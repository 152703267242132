import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonLink,
  Column,
  Columns,
  Heading,
  Hidden,
  IconChevron,
  Inline,
  List,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import { memo, useMemo } from 'react';

import translations from './.vocab';

const LMIS_CAMPAIGN_CODE = 'lrn:skj:sklm:jbs:btm:banner';

const links = {
  homeHeader: `/learning?CampaignCode=${LMIS_CAMPAIGN_CODE}:hdr`,
  homeBtn: `/learning?CampaignCode=${LMIS_CAMPAIGN_CODE}:btn`,
  homeLogo: `/learning?CampaignCode=${LMIS_CAMPAIGN_CODE}:lg`,
};

const DefaultLmis = () => {
  const { t } = useTranslations(translations);

  const logo = useMemo(
    () => (
      <TextLink href={links.homeLogo} target="_blank">
        <img
          alt={t('SEEK Learning')}
          src="https://seekcdn.com/skl/laasie/logo.svg"
          width={120}
          height={24}
        />
      </TextLink>
    ),
    [t],
  );

  const courseListItems = useMemo(
    () =>
      [
        {
          title: t('TAFE'),
          message: t('TAFE'),
          href: `/learning/search/tafe-courses?CampaignCode=${LMIS_CAMPAIGN_CODE}:lnk`,
        },
        {
          title: t('Online'),
          message: t('Online'),
          href: `/learning/search/courses/study-online?CampaignCode=${LMIS_CAMPAIGN_CODE}:lnk`,
        },
        {
          title: t('Courses by industry'),
          message: t('Courses by industry'),
          href: `/learning/careers?CampaignCode=${LMIS_CAMPAIGN_CODE}:lnk`,
        },
      ].map(({ title, message, href }) => (
        <Text key={href} component="li">
          <TextLink href={href} target="_blank" title={title}>
            {message}
          </TextLink>
        </Text>
      )),
    [t],
  );

  return (
    <Box paddingY="xsmall" component="section" data-automation="default-lmis">
      <Stack space="gutter">
        <Columns alignY="center" space={{ mobile: 'none', tablet: 'gutter' }}>
          <Column>
            <Heading level="3" component="h1">
              <TextLink href={links.homeHeader} target="_blank">
                {t('Courses that get you job-ready')}
              </TextLink>
            </Heading>
          </Column>
          <Column width="content">
            <Hidden below="tablet">{logo}</Hidden>
          </Column>
        </Columns>

        <List>
          <Text>{t('Industry recognised providers')}</Text>
          <Text>{t('Experts in career related education')}</Text>
        </List>

        <Columns
          collapseBelow="tablet"
          space={{ mobile: 'none', tablet: 'gutter' }}
          alignY="center"
        >
          <Column>
            <Hidden below="tablet">
              <Inline space="large" component="ul">
                {courseListItems}
              </Inline>
            </Hidden>
          </Column>
          <Column width="content">
            <ButtonLink href={links.homeBtn} tone="neutral" target="_blank">
              {t('Explore courses')} <IconChevron direction="right" />
            </ButtonLink>
          </Column>
        </Columns>

        <Hidden above="mobile">{logo}</Hidden>
      </Stack>
    </Box>
  );
};

export default memo(DefaultLmis);
