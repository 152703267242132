import {
  qualifyRelatedSearch,
  linkRelForKeyword,
} from '@seek/related-search-seo';
import { useTranslations } from '@vocab/react';
import { Heading, Inline, Stack } from 'braid-design-system';
import isEmpty from 'lodash/isEmpty';
import { stringify } from 'qs';
import { useMemo } from 'react';

import clean from 'src/modules/clean-object';
import { useSelector } from 'src/store/react';
import {
  selectResultsLocationDescription,
  selectWhereField,
} from 'src/store/selectors';

import type { FilterList } from '../SeoFooter/useSeoFooter';

import translations from './.vocab';
import RelatedSearchItem, {
  type RelatedSearchItemProps,
} from './RelatedSearchItem';

interface Props {
  filterInfo: FilterList;
}

interface ValidFacets {
  id: string;
  count: number;
  label: string;
}

const RelatedSearches = ({ filterInfo }: Props) => {
  const { t } = useTranslations(translations);

  const resultsLocationDescription = useSelector(
    selectResultsLocationDescription,
  );

  const whereField = useSelector(selectWhereField);

  const filterListType = filterInfo?.filterListType;

  const links = useMemo<RelatedSearchItemProps[]>(() => {
    if (!filterInfo) return [];
    if (filterListType === 'facets') {
      const firstFiveValidFacets = filterInfo?.filteredSearches
        .filter((facet) => facet.label)
        .slice(0, 5) as ValidFacets[];

      return firstFiveValidFacets.map((facetsLabel, index) => {
        const cleanQuery = clean({
          keywords: facetsLabel.label.toLowerCase(),
          where: !isEmpty(whereField) ? resultsLocationDescription : undefined,
        });
        const queryParams = stringify(cleanQuery);
        return {
          text: facetsLabel.label.toLowerCase(),
          href: `/jobs${queryParams.length > 0 ? `?${queryParams}` : ''}`,
          rel: undefined,
          position: index + 1,
          type: 'facets',
        };
      });
    } else if (filterListType === 'related') {
      return filterInfo.filteredSearches.map((relatedSearchKeyword, index) => {
        const cleanQuery = clean({
          ...qualifyRelatedSearch(relatedSearchKeyword),
          where: !isEmpty(whereField) ? resultsLocationDescription : undefined,
        });

        const queryParams = stringify(cleanQuery);
        const maybeRel = linkRelForKeyword(
          relatedSearchKeyword.keywords,
          relatedSearchKeyword.type,
        );

        return {
          text: `${relatedSearchKeyword.keywords.toLowerCase()} ${t('jobs')}`,
          href: `/jobs${queryParams.length > 0 && `?${queryParams}`}`,
          rel: maybeRel === null ? undefined : maybeRel,
          position: index + 1,
          type: 'related',
        };
      });
    }
    return [];
  }, [filterInfo, filterListType, t, whereField, resultsLocationDescription]);

  return (
    <Stack space="medium" data={{ automation: 'related-searches' }}>
      <Heading level="4" weight="regular">
        {filterListType === 'facets'
          ? t('Refine by role title')
          : t('Related Searches')}
      </Heading>
      <Inline space="small">
        {links.map(({ text, href, position, rel, type }) => (
          <RelatedSearchItem
            key={position}
            text={text}
            href={href}
            position={position}
            rel={rel}
            type={type}
          />
        ))}
      </Inline>
    </Stack>
  );
};

export default RelatedSearches;
