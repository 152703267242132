import { createForm } from '@seek/forms-ui';
import { isEmail, required } from '@seek/validators-js';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  IconCritical,
  Stack,
  Text,
  TextField,
  TextLink,
  useResponsiveValue,
} from 'braid-design-system';
import {
  useCallback,
  useState,
  useEffect,
  type ChangeEvent,
  type FormEventHandler,
  type RefObject,
} from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useSelector, useDispatch } from 'src/store/react';
import { resetSaveSearch } from 'src/store/saveSearch';
import {
  type JobMailPosition,
  SavedSearchStatus,
} from 'src/store/saveSearch/types';
import {
  selectSaveSearchEmailAddress,
  selectSaveSearchErrorMessage,
} from 'src/store/selectors';
import type { ExtractFromArray } from 'src/types/globals';

import translations from './.vocab';
import useUnregisteredSaveSearch from './useUnregisteredSaveSearch';

import * as styles from './UnregisteredSaveSearch.css';

export interface JobMailProps {
  position: JobMailPosition;
  inputRef?: RefObject<HTMLInputElement>;
}

export interface UnregisteredSaveSearchFormValues {
  jobMailLiteEmail: string;
}

type FormProps = JobMailProps & {
  status: SavedSearchStatus;
  handleSubmit: FormEventHandler;
};

const { FormProvider, useField } =
  createForm<UnregisteredSaveSearchFormValues>('sendJobForm');

const defaultInitialValues: UnregisteredSaveSearchFormValues = {
  jobMailLiteEmail: '',
};

const dataAutomationPrefix = 'jobmail';

const FormDisclaimer = () => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  return (
    <Text tone="secondary" size="small">
      {t('You can cancel emails at any time. See our')}{' '}
      <TextLink
        onClick={() => analyticsFacade.saveSearchFormDisclaimerLinkClicked()}
        href="/privacy"
        weight="weak"
      >
        {t('Privacy Policy')}
      </TextLink>
    </Text>
  );
};

const ErrorMessage = ({ message }: { message: string }) => (
  <Text icon={<IconCritical />} size="small" tone="critical">
    {message}
  </Text>
);
interface Errors {
  validationError: string;
  errorMessage?: string;
}
const renderErrors = ({ errorMessage, validationError }: Errors) =>
  errorMessage || validationError ? (
    <Box
      paddingBottom={{
        mobile: 'medium',
        tablet: 'none',
        desktop: 'medium',
        wide: 'none',
      }}
    >
      <Stack data={{ automation: 'jobmail-invalid-message' }} space="small">
        {validationError ? (
          <ErrorMessage message={`${validationError}.`} />
        ) : null}
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      </Stack>
    </Box>
  ) : null;

const UnregisteredSaveSearchForm = (props: FormProps) => {
  const { handleSubmit, position, status, inputRef } = props;
  const errorMessage = useSelector(selectSaveSearchErrorMessage);
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [errors, setErrors] = useState({ errorMessage, validationError: '' });
  const [shouldRenderErrors, setShouldRenderErrors] = useState(false);
  const dispatch = useDispatch();

  const onFocus = useCallback(() => {
    setShowDisclaimer(true);
  }, []);

  const isSaving = status === SavedSearchStatus.SAVING;
  const requiredField: ExtractFromArray<
    Parameters<typeof useField>['0']['validators']
  > = {
    ...required,
    getErrorMessage: ({ validatorProps: { requiredLabel } }) =>
      Promise.resolve(shouldRenderErrors ? requiredLabel : ''),
  };

  const { errorMessage: validationError, ...emailField } = useField({
    id: 'jobMailLiteEmail',
    validators: [requiredField, isEmail],
    validatorProps: {
      requiredLabel: t("Your email can't be blank"),
    },
  });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (errorMessage.length && status === SavedSearchStatus.DUPLICATE) {
        dispatch(resetSaveSearch());
      }

      if (!shouldRenderErrors && e.target.value.length) {
        setShouldRenderErrors(true);
      }
      emailField.onChange(e);
    },
    [shouldRenderErrors, emailField, dispatch, status, errorMessage],
  );

  useEffect(() => {
    setErrors({
      errorMessage,
      validationError,
    });
  }, [errorMessage, validationError, analyticsFacade]);

  const isDesktopOrBelow = useResponsiveValue()({
    mobile: true,
    tablet: true,
    desktop: true,
    wide: false,
  });

  const hasErrors = Object.values(errors).filter(Boolean).length > 0;
  return (
    <form
      data-automation={`${dataAutomationPrefix}-create-${position}`}
      onSubmit={handleSubmit}
      // This is to ensure we don't show the browser's default email validation error.
      // Remove this should we ever remove error handling for email field.
      noValidate
    >
      <Stack space="small">
        <Stack space="xsmall">
          <Box
            display={{
              mobile: 'block',
              tablet: 'flex',
              desktop: 'block',
              wide: 'flex',
            }}
          >
            <Stack space="xsmall">
              <Box
                className={styles.inputContainerSplitView}
                paddingRight={{
                  tablet: 'xxsmall',
                  desktop: 'none',
                  wide: 'xxsmall',
                }}
                paddingBottom={{
                  mobile: !hasErrors ? 'small' : 'none',
                  desktop: !hasErrors ? 'small' : 'none',
                }}
              >
                <TextField
                  ref={inputRef}
                  aria-label={t('Enter your email')}
                  data={{ automation: 'jobmail' }}
                  id="jobMailLiteEmail"
                  onFocus={onFocus}
                  placeholder={t('Enter your email')}
                  tone={hasErrors ? 'critical' : undefined}
                  type="email"
                  {...emailField}
                  onChange={onChange}
                />
              </Box>
              {isDesktopOrBelow && renderErrors(errors)}
            </Stack>
            <Stack space="none">
              <Box
                className={styles.buttonSplitView}
                data-testid="create-alert-button-container"
              >
                <Button
                  type="submit"
                  tone="formAccent"
                  loading={isSaving}
                  data={{
                    automation: 'jobmail-submit',
                  }}
                  onClick={() => setShouldRenderErrors(true)}
                >
                  {isSaving ? t('Saving') : t('Create alert')}
                </Button>
              </Box>
            </Stack>
          </Box>
          {!isDesktopOrBelow && renderErrors(errors)}
        </Stack>
        {showDisclaimer ? <FormDisclaimer /> : null}
      </Stack>
    </form>
  );
};

export default (props: JobMailProps) => {
  const { onSubmit, status } = useUnregisteredSaveSearch(props);
  const { locale } = useAppConfig();
  const emailAddress = useSelector(selectSaveSearchEmailAddress);
  const initialValues: UnregisteredSaveSearchFormValues = {
    jobMailLiteEmail: emailAddress,
  };

  return (
    <FormProvider
      initialValues={{ ...defaultInitialValues, ...initialValues }}
      language={locale}
    >
      {({ handleSubmit }) => (
        <UnregisteredSaveSearchForm
          {...props}
          status={status}
          handleSubmit={handleSubmit(onSubmit)}
        />
      )}
    </FormProvider>
  );
};
