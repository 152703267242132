interface StickySerpTargetProps {
  name: string;
  component?: any;
  children?: any;
  className?: string;
  role?: string;
}

export default ({
  name,
  component: Wrapper = 'div',
  children,
  ...restProps
}: StickySerpTargetProps) => (
  <Wrapper name={name} {...restProps}>
    {children}
  </Wrapper>
);
