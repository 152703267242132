import type { UrlLocation } from '@seek/chalice-types';
import { stringify } from 'query-string';
import { type ComponentProps, useCallback, useMemo } from 'react';

import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import trackingClient from 'src/modules/job-tracking';
import { useDispatch, useSelector } from 'src/store/react';
import { resultLinkClicked } from 'src/store/results';
import {
  selectEventCaptureTestTags,
  selectSearchQuery,
} from 'src/store/selectors';

import type JobListItem from '../JobListItem';

const useJDPNavLinkProps = (
  job: ComponentProps<typeof JobListItem>['job'],
): { location: UrlLocation; onBeforeNavigation: () => void } => {
  const dispatch = useDispatch();
  const testTags = useSelector(selectEventCaptureTestTags);
  const { savedsearchid: savedSearchId = '' } = useSelector(selectSearchQuery);
  const onBeforeNavigation = useCallback(() => {
    trackingClient.trackJobClick(job.tracking, testTags);
    dispatch(resultLinkClicked(job));
  }, [dispatch, job, testTags]);

  const solHash = useSolMetadataReference({
    jobId: typeof job.id === 'string' ? parseInt(job.id, 10) : job.id, // id is a string in V5
    displayType: job.displayType,
  });

  // Generate a url hash for use with sol tracking
  const urlHash = useMemo(() => stringify({ sol: solHash }), [solHash]);

  return {
    onBeforeNavigation,
    location: {
      pathname: `/job/${job.id}`,
      query: {
        type: job.displayType,
        ref: savedSearchId ? 'savedsearch' : 'search-standalone',
      },
      ...(urlHash.length
        ? {
            hash: urlHash,
          }
        : {}),
    },
  };
};

export default useJDPNavLinkProps;
