
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiU2lnbiBpbiIsIlJlZ2lzdGVyIjoiUmVnaXN0ZXIiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0In0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiTWFzdWsiLCJSZWdpc3RlciI6IkRhZnRhciIsIlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiTWFzdWsgdW50dWsgZm9rdXMgcGFkYSBwZWtlcmphYW4geWFuZyBiZWx1bSBBbmRhIGplbGFqYWhpIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiTWFzdWsiLCJSZWdpc3RlciI6IkRhZnRhciIsIlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiTWFzdWsgdW50dWsgZm9rdXMgcGFkYSBwZWtlcmphYW4geWFuZyBiZWx1bSBBbmRhIGplbGFqYWhpIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIiwiUmVnaXN0ZXIiOiLguKXguIfguJfguLDguYDguJrguLXguKLguJkiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IuC4peC4h+C4iuC4t+C5iOC4reC5gOC4guC5ieC4suC5g+C4iuC5ieC5gOC4nuC4t+C5iOC4reC5gOC4o+C4teC4ouC4geC4lOC4ueC4h+C4suC4meC4l+C4teC5iOC4hOC4uOC4k+C4ouC4seC4h+C5hOC4oeC5iOC5hOC4lOC5ieC5gOC4o+C4teC4ouC4geC4lOC4uSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoi4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaIiwiUmVnaXN0ZXIiOiLguKXguIfguJfguLDguYDguJrguLXguKLguJkiLCJTaWduIGluIHRvIGZvY3VzIG9uIGpvYnMgeW91J3ZlIG5vdCBicm93c2VkIHlldCI6IuC4peC4h+C4iuC4t+C5iOC4reC5gOC4guC5ieC4suC5g+C4iuC5ieC5gOC4nuC4t+C5iOC4reC5gOC4o+C4teC4ouC4geC4lOC4ueC4h+C4suC4meC4l+C4teC5iOC4hOC4uOC4k+C4ouC4seC4h+C5hOC4oeC5iOC5hOC4lOC5ieC5gOC4o+C4teC4ouC4geC4lOC4uSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJXSIsIlJlZ2lzdGVyIjoiW8WY4bq94bq94bq9xKPDrMOsw6zFoeG5r+G6veG6veG6vcWZXSIsIlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIOG5r8O2w7bDtiDGksO2w7bDtsOnx5rHmseaxaEgw7bDtsO24LiB4Li14LmJIMS1w7bDtsO2w5/FoSDDvcO9w73DtsO2w7bHmseax5on4bm94bq94bq94bq9IOC4geC4teC5icO2w7bDtuG5ryDDn8WZw7bDtsO2xbXFoeG6veG6veG6vcaMIMO9w73DveG6veG6veG6veG5r10ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJXSIsIlJlZ2lzdGVyIjoiW8WY4bq94bq94bq9xKPDrMOsw6zFoeG5r+G6veG6veG6vcWZXSIsIlNpZ24gaW4gdG8gZm9jdXMgb24gam9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIOG5r8O2w7bDtiDGksO2w7bDtsOnx5rHmseaxaEgw7bDtsO24LiB4Li14LmJIMS1w7bDtsO2w5/FoSDDvcO9w73DtsO2w7bHmseax5on4bm94bq94bq94bq9IOC4geC4teC5icO2w7bDtuG5ryDDn8WZw7bDtsO2xbXFoeG6veG6veG6vcaMIMO9w73DveG6veG6veG6veG5r10ifQ==!"
        )
      )
      });
  
      export { translations as default };
    