import { useTranslations } from '@vocab/react';
import { Text, TextLink } from 'braid-design-system';
import type { ReactNode } from 'react';

import translations from './.vocab';
import useSeoFooter from './useSeoFooter';

export const SEOPartnerSites = () => {
  const { partners, hasExternalJobs, tabIndex } = useSeoFooter();
  const { t } = useTranslations(translations);
  return (
    <Text size="small">
      {partners.label
        ? t('Find more...', {
            label: partners.label,
            TextLink: (children: ReactNode) => (
              <TextLink
                data-automation="partner-search-seo"
                href={partners.href}
                target="_blank"
                weight="weak"
                tabIndex={tabIndex}
                rel={['noopener', hasExternalJobs ? 'nofollow' : '']
                  .filter(Boolean)
                  .join(' ')}
              >
                {children}
              </TextLink>
            ),
          })
        : t('Find more on Jora')}
    </Text>
  );
};
