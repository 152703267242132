
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIlRoZXJl4oCZcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iOiJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIiwiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiI6Ildl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iLCJUaGFua3Mge25hbWV9ISI6IlRoYW5rcyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IkhpIHtuYW1lfSwifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiUGVyZ2kga2UgcHJvZmlsIiwiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4iLCJXZSB3ZXJlbuKAmXQgYWJsZSB0byBzYXZlIHlvdXIgYW5zd2VyIHRvIHlvdXIgcHJvZmlsZS4iOiJLYW1pIHRpZGFrIGRhcGF0IG1lbnlpbXBhbiBqYXdhYmFuIGtlIHByb2ZpbCBrYW11LiIsIldl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iOiJLYW1pIHRlbGFoIG1lbXBlcmJhcnVpIFByb2ZpbCB7YnJhbmR9IEFuZGEuIiwiVGhhbmtzIHtuYW1lfSEiOiJUZXJpbWEga2FzaWgge25hbWV9LCIsIkhpIHtuYW1lfSwiOiJIYWkge25hbWV9ISJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiUGVyZ2kga2UgcHJvZmlsIiwiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4iLCJXZSB3ZXJlbuKAmXQgYWJsZSB0byBzYXZlIHlvdXIgYW5zd2VyIHRvIHlvdXIgcHJvZmlsZS4iOiJLYW1pIHRpZGFrIGRhcGF0IG1lbnlpbXBhbiBqYXdhYmFuIGtlIHByb2ZpbCBrYW11LiIsIldl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iOiJLYW1pIHRlbGFoIG1lbXBlcmJhcnVpIFByb2ZpbCB7YnJhbmR9IEFuZGEuIiwiVGhhbmtzIHtuYW1lfSEiOiJUZXJpbWEga2FzaWgge25hbWV9LCIsIkhpIHtuYW1lfSwiOiJIYWkge25hbWV9ISJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoi4LmE4Lib4Lii4Lix4LiH4LmC4Lib4Lij4LmE4Lif4Lil4LmMIiwiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiI6IuC4nuC4muC4guC5ieC4reC4nOC4tOC4lOC4nuC4peC4suC4lOC4muC4suC4h+C4reC4ouC5iOC4suC4hyIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IuC5gOC4o+C4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC4l+C4s+C4geC4suC4o+C4muC4seC4meC4l+C4tuC4geC4hOC4s+C4leC4reC4muC4guC4reC4h+C4hOC4uOC4k+C4peC4h+C4muC4meC5guC4m+C4o+C5hOC4n+C4peC5jOC5hOC4lOC5iSIsIldl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iOiLguYDguKPguLLguYTguJTguYnguJfguLPguIHguLLguKPguK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ/guKXguYwge2JyYW5kfSDguILguK3guIfguITguLjguJPguYHguKXguYnguKciLCJUaGFua3Mge25hbWV9ISI6IuC4guC4reC4muC4hOC4uOC4kyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IuC4quC4p+C4seC4quC4lOC4tSB7bmFtZX0sIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoi4LmE4Lib4Lii4Lix4LiH4LmC4Lib4Lij4LmE4Lif4Lil4LmMIiwiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiI6IuC4nuC4muC4guC5ieC4reC4nOC4tOC4lOC4nuC4peC4suC4lOC4muC4suC4h+C4reC4ouC5iOC4suC4hyIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IuC5gOC4o+C4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC4l+C4s+C4geC4suC4o+C4muC4seC4meC4l+C4tuC4geC4hOC4s+C4leC4reC4muC4guC4reC4h+C4hOC4uOC4k+C4peC4h+C4muC4meC5guC4m+C4o+C5hOC4n+C4peC5jOC5hOC4lOC5iSIsIldl4oCZdmUgdXBkYXRlZCB5b3VyIHticmFuZH0gUHJvZmlsZS4iOiLguYDguKPguLLguYTguJTguYnguJfguLPguIHguLLguKPguK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ/guKXguYwge2JyYW5kfSDguILguK3guIfguITguLjguJPguYHguKXguYnguKciLCJUaGFua3Mge25hbWV9ISI6IuC4guC4reC4muC4hOC4uOC4kyB7bmFtZX0hIiwiSGkge25hbWV9LCI6IuC4quC4p+C4seC4quC4lOC4tSB7bmFtZX0sIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiW8emw7bDtsO2IOG5r8O2w7bDtiDGpcWZw7bDtsO2xpLDrMOsw6zGmuG6veG6veG6vV0iLCJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiW+G5ruG4qeG6veG6veG6vcWZ4bq94bq94bq94oCZxaEgxaHDtsO2w7ZtzILhur3hur3hur3hua/huKnDrMOsw6zguIHguLXguYnEoyDFtcWZw7bDtsO24LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOG6veG6veG6veC4geC4teC5icaMLl0iLCJXZSB3ZXJlbuKAmXQgYWJsZSB0byBzYXZlIHlvdXIgYW5zd2VyIHRvIHlvdXIgcHJvZmlsZS4iOiJbxbThur3hur3hur0gxbXhur3hur3hur3FmeG6veG6veG6veC4geC4teC5ieKAmeG5ryDEg8SDxIPDn8aa4bq94bq94bq9IOG5r8O2w7bDtiDFocSDxIPEg+G5veG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPguIHguLXguYnFocW14bq94bq94bq9xZkg4bmvw7bDtsO2IMO9w73DvcO2w7bDtseax5rHmsWZIMalxZnDtsO2w7bGksOsw6zDrMaa4bq94bq94bq9Ll0iLCJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiW8W04bq94bq94oCZ4bm94bq94bq9IMeax5rGpcaMxIPEg+G5r+G6veG6vcaMIMO9w73DtsO2x5rHmsWZIHticmFuZH0gxqTFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0uXSIsIlRoYW5rcyB7bmFtZX0hIjoiW+G5ruG4qcSDxIPEg8SD4LiB4Li14LmJxLfFoSB7bmFtZX0hXSIsIkhpIHtuYW1lfSwiOiJb4biow6zDrMOsIHtuYW1lfSxdIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiW8emw7bDtsO2IOG5r8O2w7bDtiDGpcWZw7bDtsO2xpLDrMOsw6zGmuG6veG6veG6vV0iLCJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiW+G5ruG4qeG6veG6veG6vcWZ4bq94bq94bq94oCZxaEgxaHDtsO2w7ZtzILhur3hur3hur3hua/huKnDrMOsw6zguIHguLXguYnEoyDFtcWZw7bDtsO24LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOG6veG6veG6veC4geC4teC5icaMLl0iLCJXZSB3ZXJlbuKAmXQgYWJsZSB0byBzYXZlIHlvdXIgYW5zd2VyIHRvIHlvdXIgcHJvZmlsZS4iOiJbxbThur3hur3hur0gxbXhur3hur3hur3FmeG6veG6veG6veC4geC4teC5ieKAmeG5ryDEg8SDxIPDn8aa4bq94bq94bq9IOG5r8O2w7bDtiDFocSDxIPEg+G5veG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPguIHguLXguYnFocW14bq94bq94bq9xZkg4bmvw7bDtsO2IMO9w73DvcO2w7bDtseax5rHmsWZIMalxZnDtsO2w7bGksOsw6zDrMaa4bq94bq94bq9Ll0iLCJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiW8W04bq94bq94oCZ4bm94bq94bq9IMeax5rGpcaMxIPEg+G5r+G6veG6vcaMIMO9w73DtsO2x5rHmsWZIHticmFuZH0gxqTFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0uXSIsIlRoYW5rcyB7bmFtZX0hIjoiW+G5ruG4qcSDxIPEg8SD4LiB4Li14LmJxLfFoSB7bmFtZX0hXSIsIkhpIHtuYW1lfSwiOiJb4biow6zDrMOsIHtuYW1lfSxdIn0=!"
        )
      )
      });
  
      export { translations as default };
    