import { includedKeyword } from '@seek/related-search-seo';
import { useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { useSERPHasExternalJobs } from 'src/modules/hooks/useSERPHasExternalJobs';
import { useSelector } from 'src/store/react';
import type { FacetType, RelatedSearch } from 'src/store/results/types';
import {
  selectFacets,
  selectFeatureFlag,
  selectJoraCrossLink,
  selectRelatedSearches,
  selectSearchQuery,
} from 'src/store/selectors';

export type FilterList =
  | { filterListType: 'related'; filteredSearches: RelatedSearch[] }
  | { filterListType: 'facets'; filteredSearches: FacetType[] }
  | undefined;

const useSeoFooter = () => {
  const hasExternalJobs = useSERPHasExternalJobs();
  const partners = useSelector(selectJoraCrossLink);
  const relatedSearches = useSelector(selectRelatedSearches);
  const facets = useSelector(selectFacets);
  const searchQuery = useSelector(selectSearchQuery);
  const { zone } = useAppConfig();
  const isV5Search = useSelector(selectFeatureFlag('v5JobSearch'));

  const isLocationOnlySerp =
    searchQuery &&
    !searchQuery.keywords &&
    !searchQuery.classification &&
    searchQuery.where;

  const nonEnglishZones = ['asia-3', 'asia-4'];

  const shouldRenderTitleFacets =
    !nonEnglishZones.includes(zone) && isLocationOnlySerp;

  const shouldRenderSeoPartners = isV5Search
    ? partners != null
    : Boolean(partners?.canCrossLink);

  return useMemo(() => {
    const filteredSearches = relatedSearches
      ? relatedSearches.filter(({ keywords }) => includedKeyword(keywords))
      : [];

    const titleFacets =
      facets && facets.title
        ? facets.title.filter(({ label }) => label && includedKeyword(label))
        : [];

    let relatedList: FilterList;

    if (shouldRenderTitleFacets && titleFacets.length > 0) {
      relatedList = { filterListType: 'facets', filteredSearches: titleFacets };
    } else if (filteredSearches.length > 0) {
      relatedList = { filterListType: 'related', filteredSearches };
    }

    const shouldRenderRelatedSearch =
      relatedList && relatedList?.filteredSearches.length > 0;

    return {
      tabIndex: -1,
      hasExternalJobs,
      partners,
      relatedList,
      shouldRenderRelatedSearch,
      shouldRenderSeoPartners,
    };
  }, [
    relatedSearches,
    facets,
    shouldRenderTitleFacets,
    hasExternalJobs,
    partners,
    shouldRenderSeoPartners,
  ]);
};

export default useSeoFooter;
