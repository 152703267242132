import { Box } from 'braid-design-system';

import type { SearchViewModelHeader } from 'src/types/globals';

import FormattedMessage from '../FormattedMessage/FormattedMessage';
import DistanceFrom from '../SearchResultSummary/DistanceFrom/DistanceFrom';

interface SearchResultsDistanceProps {
  message?: SearchViewModelHeader | null;
}

const SearchResultsDistance = ({ message }: SearchResultsDistanceProps) => (
  <Box component="h1" data-automation="searchResultHeader">
    <DistanceFrom>
      <FormattedMessage message={message} />
    </DistanceFrom>
  </Box>
);

export default SearchResultsDistance;
