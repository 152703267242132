import { metrics } from '@seek/metrics-js';
import { getSolSearchDataProp } from '@seek/sol-js';
import { useTranslations } from '@vocab/react';
import { Box, Loader, PageBlock } from 'braid-design-system';
import clsx from 'clsx';
import { Fragment, useEffect } from 'react';

import StickySerpTarget from 'src/components/StickySerpTarget/StickySerpTarget';
import { HomePage } from 'src/handlers/Home';
import { JobDetailsPageView } from 'src/handlers/JobDetails';
import {
  PageTitle,
  useSetHubblePage,
  useSetHubbleTags,
} from 'src/modules/hubble';
import { useSelector } from 'src/store/react';
import {
  selectCompanySuggestion,
  selectIsRadialFilterShown,
  selectIsResultsHidden,
  selectIsResultsInError,
  selectIsResultsLoading,
  selectResults,
  selectSolMetadataString,
  selectUserTestHeaders,
} from 'src/store/selectors';

import translations from './.vocab';
import { useBehavioralCuesFilters } from './BehaviouralCuesFiltersContext/BehaviouralCuesFiltersContext';
import NewToYouSignIn from './NewToYouSignIn/NewToYouSignIn';
import ResultsError from './ResultsError/ResultsError';
import SearchResultsHeaders from './SearchResultHeaders/SearchResultsHeaders';
import SeoFooter from './SeoFooter/SeoFooter';
import { SkyScraperBanner } from './SplitView/SkyScraperBanner/SkyScraperBanner';
import SplitView from './SplitView/SplitView';
import UpToDateZeroCount from './UpToDateZeroCount/UpToDateZeroCount';
import ZeroResults from './ZeroResults/ZeroResults';

import * as styles from './SearchResultsPage.css';

const getRootClasses = (hideResults: boolean) =>
  clsx({
    [styles.root]: true,
    [styles.root_areResultsHidden]: hideResults,
  });

const SearchResultPage = () => {
  useSetHubblePage(PageTitle.searchResults);
  const testHeaders = useSelector(selectUserTestHeaders);
  useSetHubbleTags(testHeaders);

  const { t } = useTranslations(translations);
  const searchResultsViewModel = useSelector(selectResults);
  const isLoading = useSelector(selectIsResultsLoading);
  const resultsHidden = useSelector(selectIsResultsHidden);
  const companySuggestion = useSelector(selectCompanySuggestion);
  const solMetadataString = useSelector(selectSolMetadataString) || '';
  const isError = useSelector(selectIsResultsInError);
  const shouldShowDistanceFrom = useSelector(selectIsRadialFilterShown);
  const {
    isZeroResults = false,
    searchParams,
    jobs,
  } = searchResultsViewModel || {};
  const { header, hasHeaderBeforeJobs, locationSuggestions } =
    searchResultsViewModel || {};

  const shouldShowDistanceHeader =
    hasHeaderBeforeJobs || shouldShowDistanceFrom;
  const { state: bCuesFiltersState } = useBehavioralCuesFilters();
  const { showNewJobsSignIn, showUpToDateZeroCount } = bCuesFiltersState;

  useEffect(() => {
    JobDetailsPageView.preload();
    HomePage.preload();
  }, []);

  const SrpLoader = () => (
    <Box
      paddingY="xlarge"
      style={{ height: 400 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      data-automation="searchResultsLoader"
    >
      <Loader size="large" />
    </Box>
  );

  const renderSplitView = () => {
    const hasResults = !isError && !isZeroResults;

    return (
      <Fragment>
        {isLoading && (
          <Box width="full" className={styles.content}>
            <SrpLoader />
          </Box>
        )}
        <Box
          data-automation="searchResults"
          height="full"
          display={hasResults && !isLoading ? 'block' : 'none'}
        >
          <SplitView
            jobs={jobs || []}
            resultSummary={
              <SearchResultsHeaders
                {...{
                  shouldShowDistanceHeader,
                  header,
                  locationSuggestions,
                }}
              />
            }
            isCompanySearch={Boolean(
              searchParams && searchParams.companysearch,
            )}
          />
        </Box>
      </Fragment>
    );
  };

  useEffect(() => {
    if (isError) {
      metrics.count('ui_error', [`type:search_results`]);
    }
  }, [isError]);

  if (resultsHidden) {
    return (
      <section className={getRootClasses(resultsHidden)}>
        <span
          data-automation="hiddenSearchResults"
          {...getSolSearchDataProp(solMetadataString)}
        />
      </section>
    );
  }

  const pageBlockWidth = 'large';

  return (
    <StickySerpTarget
      component="section"
      role="region"
      aria-label={t('Search Results')}
      className={getRootClasses(isLoading)}
      name="sticky-save-search-desktop"
    >
      {/* SeoFooter needs to be high in the DOM for google */}
      <PageBlock width="large">
        <SeoFooter key="seo-footer" />
      </PageBlock>

      {/* Conditional Block for NewJobsSignIn or UpToDateZeroCount */}
      {showNewJobsSignIn || showUpToDateZeroCount ? (
        <PageBlock width={pageBlockWidth}>
          <Box display="flex">
            <Box width="full" className={styles.content}>
              {showNewJobsSignIn ? <NewToYouSignIn /> : <UpToDateZeroCount />}
            </Box>
          </Box>
        </PageBlock>
      ) : (
        <>
          {isError || isZeroResults ? (
            <PageBlock width={pageBlockWidth}>
              <Box display="flex">
                <Box
                  width="full"
                  className={styles.content}
                  paddingTop="xxlarge"
                >
                  {isError ? (
                    <ResultsError />
                  ) : (
                    <ZeroResults companySuggestion={companySuggestion} />
                  )}
                </Box>
              </Box>
            </PageBlock>
          ) : (
            <PageBlock width={pageBlockWidth}>
              <Box position="relative">
                {renderSplitView()}
                <SkyScraperBanner />
              </Box>
            </PageBlock>
          )}
        </>
      )}
    </StickySerpTarget>
  );
};
export default SearchResultPage;
