import { type ComponentProps, useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { jobViewModel } from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import {
  selectResultsLocation,
  selectSearchQuery,
  selectFeatureFlag,
} from 'src/store/selectors';
import type { JobViewModel, JobViewModelV5 } from 'src/types/JobViewModel';

import type JobListItem from '../JobListItem';

const useJobViewModel = (job: ComponentProps<typeof JobListItem>['job']) => {
  const location = useSelector(selectResultsLocation);
  const query = useSelector(selectSearchQuery);
  const { country } = useAppConfig();
  const isV5Search = useSelector(selectFeatureFlag('v5JobSearch'));

  const viewModel: JobViewModel | JobViewModelV5 = useMemo(
    () => jobViewModel({ job, query, location }, country, isV5Search),
    [job, query, location, isV5Search, country],
  );
  return viewModel;
};

export default useJobViewModel;
