export const IconSalary = () => (
  <svg
    width="105"
    height="104"
    viewBox="0 0 105 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3078_11333)">
      <path
        d="M98.0461 0H70.1371C66.7477 0 64 2.74766 64 6.13706V40.9137C64 44.3031 66.7477 47.0508 70.1371 47.0508H98.0461C101.435 47.0508 104.183 44.3031 104.183 40.9137V6.13706C104.183 2.74766 101.435 0 98.0461 0Z"
        fill="#FCD9EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.4131 15.2319H98.437V17.1155H86.4131V15.2319Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.4131 9.09521H98.437V10.9788H86.4131V9.09521Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.2412 31.7886H98.4371V33.6722H71.2412V31.7886Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.7458 25.6521H98.4373V27.5357H69.7458V25.6521Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.3748 37.9253H96.8082V39.8089H71.3748V37.9253Z"
        fill="#F8B1DC"
      />
      <path
        d="M79.2476 6.32812H73.7238C71.5058 6.32812 69.7078 8.12617 69.7078 10.3442V15.8679C69.7078 18.0859 71.5058 19.884 73.7238 19.884H79.2476C81.4656 19.884 83.2636 18.0859 83.2636 15.8679V10.3442C83.2636 8.12617 81.4656 6.32812 79.2476 6.32812Z"
        fill="#072462"
      />
      <path
        d="M80.3879 17.7947C80.3879 15.6396 78.6409 13.8926 76.4858 13.8926C74.3308 13.8926 72.5837 15.6396 72.5837 17.7947H80.3879Z"
        fill="white"
      />
      <path
        d="M76.4857 12.9177C77.5633 12.9177 78.4368 12.0442 78.4368 10.9667C78.4368 9.88914 77.5633 9.01562 76.4857 9.01562C75.4082 9.01562 74.5347 9.88914 74.5347 10.9667C74.5347 12.0442 75.4082 12.9177 76.4857 12.9177Z"
        fill="white"
      />
      <path
        d="M40.0002 22.0918C17.9087 22.0918 -1e-05 40.4274 0 63.0456C1e-05 85.6638 17.9087 103.999 40.0002 103.999C62.0915 103.999 80 85.6637 80 63.0456C80 55.9619 78.2425 49.2988 75.1512 43.4869L75.6135 43.9604L78.1213 29.2564L65.5182 31.5096C58.5928 25.6288 49.7005 22.0918 40.0002 22.0918Z"
        fill="white"
      />
      <path
        d="M16.9981 68.623L16.9512 67.1816V48.8594L18.3916 48.8125H55.252L55.2988 50.2529V68.5752L53.8584 68.623H16.9981Z"
        fill="#F470BD"
      />
      <path
        d="M53.8586 50.2065C53.8846 50.2065 53.9058 50.2277 53.9058 50.2537V67.1824C53.9058 67.2084 53.8846 67.2295 53.8586 67.2295H18.3915C18.3655 67.2295 18.3444 67.2084 18.3444 67.1824V50.2537C18.3444 50.2277 18.3655 50.2065 18.3915 50.2065H53.8586ZM53.8586 47.4199H18.3915C16.829 47.4199 15.5576 48.6912 15.5576 50.2537V67.1824C15.5576 68.7449 16.829 70.0161 18.3915 70.0161H53.8586C55.4211 70.0161 56.6925 68.7449 56.6925 67.1824V50.2537C56.6925 48.6912 55.4211 47.4199 53.8586 47.4199Z"
        fill="#EE399E"
      />
      <path
        d="M18.2588 54.6505C20.7335 54.6505 22.7397 52.6444 22.7397 50.1697H18.2588V54.6505Z"
        fill="#EE399E"
      />
      <path
        d="M22.7635 67.2292C22.7635 64.7544 20.7574 62.7483 18.2827 62.7483V67.2292H22.7635Z"
        fill="#EE399E"
      />
      <path
        d="M53.9911 62.748C51.5164 62.748 49.5103 64.7542 49.5103 67.2289H53.9911V62.748Z"
        fill="#EE399E"
      />
      <path
        d="M49.4863 50.1697C49.4863 52.6444 51.4924 54.6506 53.9672 54.6506V50.1697H49.4863Z"
        fill="#EE399E"
      />
      <path
        d="M36.125 64.7465C39.3147 64.7465 41.9005 62.1607 41.9005 58.9709C41.9005 55.7811 39.3147 53.1953 36.125 53.1953C32.9352 53.1953 30.3494 55.7811 30.3494 58.9709C30.3494 62.1607 32.9352 64.7465 36.125 64.7465Z"
        fill="#EE399E"
      />
      <path
        d="M24.5068 77.3164L24.46 75.875V57.5527L25.9004 57.5059H62.7617L62.8086 58.9463V77.2685L61.3682 77.3164H24.5068Z"
        fill="#F470BD"
      />
      <path
        d="M61.3679 58.8997C61.3939 58.8997 61.4149 58.9207 61.4149 58.9467V75.8755C61.4149 75.9015 61.3939 75.9226 61.3679 75.9226H25.9006C25.8746 75.9226 25.8536 75.9015 25.8536 75.8755V58.9467C25.8536 58.9207 25.8746 58.8997 25.9006 58.8997H61.3679ZM61.3679 56.113H25.9006C24.3381 56.113 23.0669 57.3842 23.0669 58.9467V75.8755C23.0669 77.438 24.3381 78.7092 25.9006 78.7092H61.3679C62.9304 78.7092 64.2017 77.438 64.2017 75.8755V58.9467C64.2017 57.3842 62.9304 56.113 61.3679 56.113Z"
        fill="#EE399E"
      />
      <path
        d="M25.7681 63.3441C28.2428 63.3441 30.249 61.338 30.249 58.8633H25.7681V63.3441Z"
        fill="#EE399E"
      />
      <path
        d="M30.2728 75.9223C30.2728 73.4475 28.2667 71.4414 25.792 71.4414V75.9223H30.2728Z"
        fill="#EE399E"
      />
      <path
        d="M61.5004 71.4414C59.0257 71.4414 57.0195 73.4475 57.0195 75.9222H61.5004V71.4414Z"
        fill="#EE399E"
      />
      <path
        d="M56.9958 58.8633C56.9958 61.338 59.002 63.3442 61.4767 63.3442V58.8633H56.9958Z"
        fill="#EE399E"
      />
      <path
        d="M43.6342 72.9125C46.824 72.9125 49.4098 70.3267 49.4098 67.1369C49.4098 63.9471 46.824 61.3613 43.6342 61.3613C40.4445 61.3613 37.8586 63.9471 37.8586 67.1369C37.8586 70.3267 40.4445 72.9125 43.6342 72.9125Z"
        fill="#EE399E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0451 62.498V70.0315H22.5742V62.498H23.0451Z"
        fill="#F470BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.1472 55.624H56.6807V56.0949H49.1472V55.624Z"
        fill="#F470BD"
      />
    </g>
    <defs>
      <clipPath id="clip0_3078_11333">
        <rect width="104.183" height="104" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
