import { memo } from 'react';

const InitialViewIcon = () => (
  <svg
    id="initialViewIcon"
    data-name="initialViewIconSVG"
    xmlns="http://www.w3.org/2000/svg"
    height="192"
    width="192"
    viewBox="0 0 192 192"
  >
    <g id="Layer_72" data-name="Layer 72">
      <g id="mask0_4649_8406" data-name="mask0 4649 8406">
        <path
          strokeWidth="0"
          fill="#fcd9ef"
          d="M96,192c53.02,0,96-42.98,96-96S149.02,0,96,0,0,42.98,0,96s42.98,96,96,96Z"
        />
      </g>
      <g>
        <g>
          <path
            strokeWidth="0"
            fill="#fcd9ef"
            d="M96,0C42.98,0,0,42.98,0,96,0,124.35,12.29,149.82,31.82,167.39V53.02c0-4.97,4.03-9,9-9h110.05c4.97,0,9,4.03,9,9v114.64c19.71-17.58,32.12-43.17,32.12-71.66C192,42.98,149.02,0,96,0Z"
          />
          <path
            d="M150.88,44.02H40.82c-4.97,0-9,4.03-9,9v114.37c17.01,15.3,39.5,24.61,64.18,24.61s46.91-9.21,63.88-24.34V53.02c0-4.97-4.03-9-9-9Z"
            fill="#fff"
            strokeWidth="0"
          />
        </g>
        <path
          d="M159.88,56.02H31.82v26.01h128.05v-26.01Z"
          fill="#051a49"
          strokeWidth="0"
        />
        <path
          fillRule="evenodd"
          fill="#072462"
          d="M67.13,79.01c0-4.76-3.84-8.64-8.6-8.64h-.16v8.64h8.78-.02Z"
        />
        <path
          d="M31.85,76.35c3.19,0,5.77,2.54,5.77,5.68h11.55c0-9.42-7.76-17.07-17.34-17.07l.02,11.38Z"
          fillRule="evenodd"
          fill="#e60278"
          strokeWidth="0"
        />
        <path
          d="M53.98,67.25c2.42,0,4.39-1.93,4.39-4.32s-1.97-4.32-4.39-4.32-4.39,1.93-4.39,4.32,1.97,4.32,4.39,4.32Z"
          fill="#e60278"
          strokeWidth="0"
        />
        <path
          fillRule="evenodd"
          fill="#072462"
          d="M124 59c0 5 4 9 9 9h0v-9h-9 0ZM137 79c2 0 4-2 4-4s-2-4-4-4-4 2-4 4 2 4 4 4ZM137 76c1 0 1-1 1-1s-1-1-1-1-1 1-1 1 1 1 1 1ZM160 56h-17c0 9 8 17 17 17l0-17Z"
        />
        <path
          fill="#ee399e"
          d="M53 90c-1 0-1 0-1-1v-4c0-1 0-1 1-1s1 0 1 1v4c0 1 0 1-1 1ZM47 92c0 0-1 0-1 0l-3-3c0 0 0-1 0-1 0 0 1 0 1 0l3 3c0 0 0 1 0 1 0 0 0 0-1 0ZM45 98h-4c-1 0-1 0-1-1s0-1 1-1h4c1 0 1 0 1 1s0 1-1 1Z"
        />
        <rect
          x="102.81"
          y="94"
          width="40"
          height="81"
          rx="5.89"
          ry="5.89"
          fill="#edf2f9"
          strokeWidth="0"
        />
        <path
          d="M87.06,130.78h-32.36c-3.25,0-5.88-2.64-5.88-5.89v-27.01c0-3.25,2.64-5.89,5.88-5.89h32.36c3.25,0,5.89,2.64,5.89,5.89v27.01c0,3.25-2.64,5.89-5.89,5.89ZM54.7,94.4c-1.92,0-3.48,1.56-3.48,3.48v27.01c0,1.92,1.56,3.49,3.48,3.49h32.36c1.92,0,3.49-1.56,3.49-3.49v-27.01c0-1.92-1.56-3.48-3.49-3.48h-32.36Z"
          fill="#2765cf"
          strokeWidth="0"
        />
        <path
          fill="#dce5f2"
          d="M73 105h-17v3h17v-3ZM73 112h-17v3h17v-3ZM86 119h-29v3h29v-3ZM86 112c0 0 0 0 0 0l-4-2-4 2c0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0v-11c0-1 0-1 0-1 0 0 1-1 1-1h6c1 0 1 0 1 1 0 0 1 1 0 1v11c0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0ZM87 175h-32c-3 0-6-3-6-6v-27c0-3 3-6 6-6h32c3 0 6 3 6 6v27c0 3-3 6-6 6ZM55 139c-2 0-3 2-3 3v27c0 2 2 3 3 3h32c2 0 3-2 3-3v-27c0-2-2-3-3-3h-32Z"
        />
        <path
          fill="#dce5f2"
          d="M73 149h-17v3h17v-3ZM73 156h-17v3h17v-3ZM86 163h-29v3h29v-3ZM86 156c0 0 0 0 0 0l-4-2-4 2c0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0v-11c0-1 0-1 0-1 0 0 1-1 1-1h6c1 0 1 0 1 1 0 0 1 1 0 1v11c0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0Z"
        />
        <path
          fill="#ee399e"
          d="M105.39,134.7l-5.58-5.58,3.21-3.21c.48-.48.31-1.3-.32-1.55l-17.14-6.89c-.78-.31-1.55.46-1.23,1.23l6.89,17.14c.25.63,1.07.8,1.55.32l3.21-3.21,5.58,5.58c.52.52,1.37.52,1.89,0l1.95-1.95c.52-.52.52-1.37,0-1.89Z"
        />
      </g>
    </g>
  </svg>
);

export default memo(InitialViewIcon);
