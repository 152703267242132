import type { SearchParams } from '@seek/chalice-types';
import { ClampLines } from '@seek/discover-ui';
import { useTranslations } from '@vocab/react';
import { Box, Text, IconSearch, Inline } from 'braid-design-system';
import omit from 'lodash/omit';
import { type ReactNode, useCallback } from 'react';

import { ButtonLink, TextLink } from 'src/components/NavLink/NavLink';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import type { SearchViewModelCompanySuggestion } from 'src/store/results/types';

import translations from './.vocab';

interface BroadenCompanySearchProps {
  keywords: string;
  suggestionCount?: SearchViewModelCompanySuggestion['count'];
  searchParams?: SearchParams;
  variant?: 'text' | 'button';
}

export const BroadenCompanySearch = ({
  keywords,
  suggestionCount,
  searchParams,
  variant = 'text',
}: BroadenCompanySearchProps) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();

  const keywordsLinkHandler = useCallback(
    () => analyticsFacade.companySuggestionLinkPressed(false),
    [analyticsFacade],
  );

  const count = Number(suggestionCount);
  const countLabel = new Intl.NumberFormat().format(count);

  const location = {
    pathname: '/jobs',
    query: {
      ...omit(searchParams, 'companysearch'),
      keywords,
    },
  };

  return variant === 'button' ? (
    <ButtonLink
      tone="formAccent"
      variant="ghost"
      icon={<IconSearch />}
      location={location}
      preNavigationHooks={[keywordsLinkHandler]}
      data={{ automation: 'company-serp-broad' }}
    >
      {t(
        'Show {countLabel} {count, plural, =0 {job} one {job} other {jobs}} containing {keywords}',
        {
          count,
          countLabel,
          keywords,
          TextLink: (children: ReactNode) => <>“{children}”</>,
        },
      )}
    </ButtonLink>
  ) : (
    <Text
      size="small"
      icon={<IconSearch />}
      data={{ automation: 'company-serp-broad' }}
    >
      {t(
        'Show {countLabel} {count, plural, =0 {job} one {job} other {jobs}} containing {keywords}',
        {
          count,
          countLabel,
          keywords,
          TextLink: (children: ReactNode) => (
            <TextLink
              location={location}
              preNavigationHooks={[keywordsLinkHandler]}
            >
              “{children}”
            </TextLink>
          ),
        },
      )}
    </Text>
  );
};

interface CompanySuggestionProps {
  companyName: string;
  suggestionCount?: SearchViewModelCompanySuggestion['count'];
  searchParams?: SearchParams;
}

const CompanySuggestion = ({
  companyName,
  suggestionCount,
  searchParams,
}: CompanySuggestionProps) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();

  const companyNameLinkHandler = useCallback(
    () => analyticsFacade.companySuggestionLinkPressed(true),
    [analyticsFacade],
  );

  const count = Number(suggestionCount);
  const countLabel = new Intl.NumberFormat().format(count);

  if (count === 0) {
    return (
      <Text maxLines={1} data={{ automation: 'company-serp-exact' }}>
        {t('0 jobs at {companyName}', { companyName })}
      </Text>
    );
  }

  const companySearchPrompt = t(
    'Show {countLabel} {count, plural, =0 {job} one {job} other {jobs}} at {companyName}',
    {
      count,
      countLabel,
      companyName,
    },
  );

  return (
    <Inline space="none">
      <ButtonLink
        location={{
          pathname: '/jobs',
          query: {
            ...searchParams,
            keywords: companyName,
            companysearch: true,
          },
        }}
        tone="formAccent"
        variant="ghost"
        preNavigationHooks={[companyNameLinkHandler]}
        data={{ automation: 'company-serp-exact' }}
      >
        <Box display="flex" alignItems="center">
          <Box paddingRight="xsmall">
            <IconSearch />
          </Box>
          <ClampLines numLines="1" originalText={companySearchPrompt}>
            {companySearchPrompt}
          </ClampLines>
        </Box>
      </ButtonLink>
    </Inline>
  );
};

export default CompanySuggestion;
