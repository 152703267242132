import type { SearchResultJob } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';

import { useLinkProps } from 'src/components/NavLink/NavLink.tsx';
import { useJobCardEnrichedLocation } from 'src/modules/enriched-location';
import { useQualifiedLinkParams } from 'src/modules/qualified-location';

import translations from '../../.vocab';

type PartialSearchResultJob = Pick<SearchResultJob, 'companyName'> & {
  advertiser: Pick<SearchResultJob['advertiser'], 'id' | 'description'>;
  isPrivateAdvertiser?: boolean;
};

const isPrivateAdvertiser = (job: PartialSearchResultJob) => {
  if ('isPrivateAdvertiser' in job) {
    // V4 responses will still have this property
    return job.isPrivateAdvertiser;
  }

  // Otherwise check the advertiser ID and description for V5 responses
  const advertiserDescription = job.advertiser.description || '';
  return (
    advertiserDescription.toLowerCase() === 'private advertiser' ||
    !job.advertiser.id
  );
};

export function useCompanyLink({ job }: { job: PartialSearchResultJob }) {
  const { t } = useTranslations(translations);
  const advertiserDescription = job.advertiser.description || '';

  const getQualifiedLinkParams = useQualifiedLinkParams({
    shouldUseUrlResolver: true,
  });
  const getJobCardEnrichedLocation = useJobCardEnrichedLocation();
  const getLinkProps = useLinkProps({
    getQualifiedLinkParams,
  });

  if (isPrivateAdvertiser(job)) {
    return {
      name: advertiserDescription,
    };
  }

  const linkProps = getLinkProps(
    getJobCardEnrichedLocation({
      pathname: '/jobs',
      query: job.companyName
        ? { keywords: job.companyName }
        : { advertiserid: job.advertiser.id },
    }),
  );

  return {
    name: advertiserDescription,
    title: t('Jobs at {companyName}', {
      companyName: job.companyName ?? advertiserDescription,
    }),
    ...linkProps,
  };
}
