import { getBlurb } from '@seek/seek-jobs-seo';
import { TextLink } from 'braid-design-system';
import { useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import { selectPageNumber, selectPathname } from 'src/store/selectors';

import useSeoFooter from './useSeoFooter';

export function useSEOBlurb() {
  const { country } = useAppConfig();
  const pathname = useSelector(selectPathname);
  const pageNumber = useSelector(selectPageNumber) || 0;
  const { tabIndex } = useSeoFooter();

  const ast = useMemo(
    () =>
      getBlurb({
        ...{ country, pathname, pageNumber },
      }),
    [country, pathname, pageNumber],
  );

  const result = useMemo(() => {
    const nodes = ast.map((part, index) => {
      if (typeof part === 'string') {
        return <span key={index}>{part}</span>;
      }
      if (typeof part === 'object') {
        return (
          <TextLink
            key={index}
            href={part.path}
            weight="weak"
            tabIndex={tabIndex}
          >
            {part.text}
          </TextLink>
        );
      }
    });
    return <>{nodes}</>;
  }, [ast, tabIndex]);

  if (ast.length < 1) {
    return null;
  }

  return result;
}
