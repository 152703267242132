import { Box, Text } from 'braid-design-system';

import { isProduction } from 'src/config';

import * as styles from './Placeholder.css';

interface PlaceholderProps {
  width?: number;
  height?: number;
  label?: string;
  ['data-automation']?: string;
}

export const Placeholder = ({
  width,
  height = 120,
  label,
  'data-automation': dataAutomation,
}: PlaceholderProps) =>
  isProduction ? null : (
    <Box
      className={styles.wrapText}
      data-automation={dataAutomation}
      display="flex"
      padding="xxsmall"
      alignItems="center"
      justifyContent="center"
      boxShadow="borderNeutralLarge"
      style={{
        width: width ? `${width}px` : 'auto',
        height: `${height}px`,
      }}
    >
      <Box width="full">
        <Text size="small" weight="strong" align="center" baseline={false}>
          {label}
        </Text>
      </Box>
    </Box>
  );
