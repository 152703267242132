export const IconProfileSuccess = () => (
  <svg
    width="62"
    height="73"
    viewBox="0 0 62 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3078_11293)">
      <path
        d="M43.9415 0.544434H7.92078C3.54625 0.544434 0 4.09075 0 8.46535V53.3506C0 57.7252 3.54625 61.2715 7.92078 61.2715H43.9415C48.316 61.2715 51.8622 57.7252 51.8622 53.3506V8.46535C51.8622 4.09075 48.316 0.544434 43.9415 0.544434Z"
        fill="#FCD9EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9277 20.2039H44.4464V22.6349H28.9277V20.2039Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9277 12.282H44.4464V14.713H28.9277V12.282Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34595 41.5732H44.4463V44.0043H9.34595V41.5732Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41577 33.6514H44.4463V36.0824H7.41577V33.6514Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51831 49.4951H42.3439V51.9262H9.51831V49.4951Z"
        fill="#F8B1DC"
      />
      <path
        d="M19.6792 8.71045H12.55C9.68734 8.71045 7.3667 11.0311 7.3667 13.8938V21.0232C7.3667 23.8859 9.68734 26.2066 12.55 26.2066H19.6792C22.5419 26.2066 24.8625 23.8859 24.8625 21.0232V13.8938C24.8625 11.0311 22.5419 8.71045 19.6792 8.71045Z"
        fill="#072462"
      />
      <path
        d="M21.1508 23.5126C21.1508 20.7312 18.896 18.4763 16.1146 18.4763C13.3332 18.4763 11.0784 20.7312 11.0784 23.5126H21.1508Z"
        fill="white"
      />
      <path
        d="M16.1148 17.2155C17.5055 17.2155 18.6329 16.0881 18.6329 14.6974C18.6329 13.3066 17.5055 12.1792 16.1148 12.1792C14.7241 12.1792 13.5967 13.3066 13.5967 14.6974C13.5967 16.0881 14.7241 17.2155 16.1148 17.2155Z"
        fill="white"
      />
      <path
        d="M45.9636 72.454C54.8203 72.454 62 65.2741 62 56.4173C62 47.5605 54.8203 40.3806 45.9636 40.3806C37.107 40.3806 29.9272 47.5605 29.9272 56.4173C29.9272 65.2741 37.107 72.454 45.9636 72.454Z"
        fill="#EE399E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.6211 51.4267C54.4098 52.2155 54.4098 53.4943 53.6211 54.283L45.2888 62.6155C44.91 62.9943 44.3963 63.2071 43.8606 63.2071C43.325 63.2071 42.8113 62.9943 42.4325 62.6155L38.123 58.306C37.3343 57.5172 37.3343 56.2384 38.1231 55.4497C38.9118 54.661 40.1906 54.661 40.9794 55.4497L43.8606 58.331L50.7648 51.4268C51.5535 50.638 52.8323 50.638 53.6211 51.4267Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3078_11293">
        <rect
          width="62.0001"
          height="71.9111"
          fill="white"
          transform="translate(0 0.544434)"
        />
      </clipPath>
    </defs>
  </svg>
);
