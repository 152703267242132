
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGxhZ2kgdGVyc2VkaWEiLCJTZWxlY3QgYW5vdGhlciBqb2Igb3IgdHJ5IGEgZGlmZmVyZW50IHNlYXJjaC4iOiJQaWxpaCBsb3dvbmdhbiBsYWluIGF0YXUgY29iYSBwZW5jYXJpYW4gbGFpbi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGxhZ2kgdGVyc2VkaWEiLCJTZWxlY3QgYW5vdGhlciBqb2Igb3IgdHJ5IGEgZGlmZmVyZW50IHNlYXJjaC4iOiJQaWxpaCBsb3dvbmdhbiBsYWluIGF0YXUgY29iYSBwZW5jYXJpYW4gbGFpbi4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoi4LmE4Lih4LmI4Lie4Lia4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiZ4Li14LmJ4LmD4LiZ4Lij4Liw4Lia4Lia4LmB4Lil4LmJ4LinIiwiU2VsZWN0IGFub3RoZXIgam9iIG9yIHRyeSBhIGRpZmZlcmVudCBzZWFyY2guIjoi4LmA4Lil4Li34Lit4LiB4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4Lil4Lit4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Lit4Li34LmI4LiZIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoi4LmE4Lih4LmI4Lie4Lia4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiZ4Li14LmJ4LmD4LiZ4Lij4Liw4Lia4Lia4LmB4Lil4LmJ4LinIiwiU2VsZWN0IGFub3RoZXIgam9iIG9yIHRyeSBhIGRpZmZlcmVudCBzZWFyY2guIjoi4LmA4Lil4Li34Lit4LiB4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4Lil4Lit4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Lit4Li34LmI4LiZIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiW+G5ruG4qcOsw6zDrMWhIMS1w7bDtsO2w58gw6zDrMOsxaEg4LiB4Li14LmJw7bDtsO2IMaaw7bDtsO24LiB4Li14LmJxKPhur3hur3hur3FmSDEg8SDxIPhub3Eg8SDxIPDrMOsw6zGmsSDxIPEg8Ofxprhur3hur3hur1dIiwiU2VsZWN0IGFub3RoZXIgam9iIG9yIHRyeSBhIGRpZmZlcmVudCBzZWFyY2guIjoiW8Wg4bq94bq94bq9xprhur3hur3hur3Dp+G5ryDEg8SDxIPguIHguLXguYnDtsO2w7bhua/huKnhur3hur3hur3FmSDEtcO2w7bDtsOfIMO2w7bDtsWZIOG5r8WZw73DvcO9IMSDxIPEgyDGjMOsw6zDrMaSxpLhur3hur3hur3FmeG6veG6veG6veC4geC4teC5ieG5ryDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiW+G5ruG4qcOsw6zDrMWhIMS1w7bDtsO2w58gw6zDrMOsxaEg4LiB4Li14LmJw7bDtsO2IMaaw7bDtsO24LiB4Li14LmJxKPhur3hur3hur3FmSDEg8SDxIPhub3Eg8SDxIPDrMOsw6zGmsSDxIPEg8Ofxprhur3hur3hur1dIiwiU2VsZWN0IGFub3RoZXIgam9iIG9yIHRyeSBhIGRpZmZlcmVudCBzZWFyY2guIjoiW8Wg4bq94bq94bq9xprhur3hur3hur3Dp+G5ryDEg8SDxIPguIHguLXguYnDtsO2w7bhua/huKnhur3hur3hur3FmSDEtcO2w7bDtsOfIMO2w7bDtsWZIOG5r8WZw73DvcO9IMSDxIPEgyDGjMOsw6zDrMaSxpLhur3hur3hur3FmeG6veG6veG6veC4geC4teC5ieG5ryDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    