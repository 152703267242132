
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJTaG93aW5nIGpvYnMgYXQiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoiU2hvd2luZyBqb2JzIHdpdGhpbiIsIlNlYXJjaCBhdCI6IlNlYXJjaCBhdCIsIlNlYXJjaCB3aXRoaW4iOiJTZWFyY2ggd2l0aGluIiwiZm9yIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSI6ImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJNZW5hbXBpbGthbiBsb3dvbmdhbiBrZXJqYSBkaSIsIlNob3dpbmcgam9icyB3aXRoaW4iOiJNZW5hbXBpbGthbiBsb3dvbmdhbiBrZXJqYSBkYWxhbSIsIlNlYXJjaCBhdCI6IkNhcmkgZGkiLCJTZWFyY2ggd2l0aGluIjoiQ2FyaSBkYWxhbSIsImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJ1bnR1ayB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJkYXJpIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJNZW5hbXBpbGthbiBsb3dvbmdhbiBrZXJqYSBkaSIsIlNob3dpbmcgam9icyB3aXRoaW4iOiJNZW5hbXBpbGthbiBsb3dvbmdhbiBrZXJqYSBkYWxhbSIsIlNlYXJjaCBhdCI6IkNhcmkgZGkiLCJTZWFyY2ggd2l0aGluIjoiQ2FyaSBkYWxhbSIsImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJ1bnR1ayB7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJkYXJpIHtsb2NhdGlvbkRlc2NyaXB0aW9ufSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiLguYHguKrguJTguIfguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguJfguLXguYgiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoi4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmD4LiZIiwiU2VhcmNoIGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmIIiwiU2VhcmNoIHdpdGhpbiI6IuC4hOC5ieC4meC4q+C4suC5g+C4mSIsImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiLguKrguLPguKvguKPguLHguJp7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiLguILguK3guId7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiLguYHguKrguJTguIfguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguJfguLXguYgiLCJTaG93aW5nIGpvYnMgd2l0aGluIjoi4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmD4LiZIiwiU2VhcmNoIGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmIIiwiU2VhcmNoIHdpdGhpbiI6IuC4hOC5ieC4meC4q+C4suC5g+C4mSIsImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiLguKrguLPguKvguKPguLHguJp7bG9jYXRpb25EZXNjcmlwdGlvbn0iLCJvZiB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiLguILguK3guId7bG9jYXRpb25EZXNjcmlwdGlvbn0ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJbxaDhuKnDtsO2w7bFtcOsw6zDrOC4geC4teC5icSjIMS1w7bDtsO2w5/FoSDEg8SDxIPhua9dIiwiU2hvd2luZyBqb2JzIHdpdGhpbiI6IlvFoOG4qcO2w7bDtsW1w6zDrMOs4LiB4Li14LmJxKMgxLXDtsO2w7bDn8WhIMW1w6zDrMOs4bmv4bipw6zDrMOs4LiB4Li14LmJXSIsIlNlYXJjaCBhdCI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxIPEg8SD4bmvXSIsIlNlYXJjaCB3aXRoaW4iOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMW1w6zDrMOs4bmv4bipw6zDrMOs4LiB4Li14LmJXSIsImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJbxpLDtsO2w7bFmSB7bG9jYXRpb25EZXNjcmlwdGlvbn1dIiwib2Yge2xvY2F0aW9uRGVzY3JpcHRpb259IjoiW8O2w7bDtsaSIHtsb2NhdGlvbkRlc2NyaXB0aW9ufV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93aW5nIGpvYnMgYXQiOiJbxaDhuKnDtsO2w7bFtcOsw6zDrOC4geC4teC5icSjIMS1w7bDtsO2w5/FoSDEg8SDxIPhua9dIiwiU2hvd2luZyBqb2JzIHdpdGhpbiI6IlvFoOG4qcO2w7bDtsW1w6zDrMOs4LiB4Li14LmJxKMgxLXDtsO2w7bDn8WhIMW1w6zDrMOs4bmv4bipw6zDrMOs4LiB4Li14LmJXSIsIlNlYXJjaCBhdCI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxIPEg8SD4bmvXSIsIlNlYXJjaCB3aXRoaW4iOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMW1w6zDrMOs4bmv4bipw6zDrMOs4LiB4Li14LmJXSIsImZvciB7bG9jYXRpb25EZXNjcmlwdGlvbn0iOiJbxpLDtsO2w7bFmSB7bG9jYXRpb25EZXNjcmlwdGlvbn1dIiwib2Yge2xvY2F0aW9uRGVzY3JpcHRpb259IjoiW8O2w7bDtsaSIHtsb2NhdGlvbkRlc2NyaXB0aW9ufV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    