import { Strong } from 'braid-design-system';
import { useMemo } from 'react';

import type {
  SearchViewModelHeader,
  SearchViewModelHeaderPart,
} from 'src/types/globals';

interface FormattedMessageProps {
  message?: SearchViewModelHeader | null;
}

const renderPart = (part: SearchViewModelHeaderPart, partIndex: number) => {
  const Part = part.highlighted ? Strong : 'span';
  return <Part key={partIndex}>{part.text}</Part>;
};

const FormattedMessage = ({ message }: FormattedMessageProps) => {
  const displayText = useMemo(() => message?.parts.map(renderPart), [message]);

  if (!displayText) {
    return null;
  }

  return <>{displayText}</>;
};

export default FormattedMessage;
