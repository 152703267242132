import { Box, Button, IconHeart } from 'braid-design-system';
import type { ComponentProps } from 'react';

interface SaveSearchButtonProps
  extends Pick<ComponentProps<typeof Button>, 'loading' | 'onClick'> {
  children: string;
}

const SaveSearchButton = ({
  children,
  loading,
  onClick,
}: SaveSearchButtonProps) => (
  <Box
    boxShadow="medium"
    borderRadius="standard"
    data-automation="SaveSearchButton"
  >
    <Button
      data={{
        automation: 'SaveSearchButtonClickable',
      }}
      tone="formAccent"
      loading={loading}
      icon={<IconHeart />}
      onClick={onClick}
    >
      {children}
    </Button>
  </Box>
);

export default SaveSearchButton;
