import type { SearchResultV5 } from '@seek/chalice-types';
import { useEffect, useState } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { type TargetingData, searchTargeting } from 'src/modules/gpt-react';
import { isClientWidthAbove } from 'src/modules/responsive-helpers';
import { useSelector } from 'src/store/react';
import { selectGPTTargeting } from 'src/store/results/selectors';
import { selectResultsLocation, selectSearchQuery } from 'src/store/selectors';

interface UseHoustonAdProps {
  minimumClientWidth: number;
}

const clean = (gptTargeting: NonNullable<SearchResultV5['gptTargeting']>) =>
  gptTargeting.filter((keyValuePair) => Boolean(keyValuePair.value));

const useHoustonAd = ({ minimumClientWidth }: UseHoustonAdProps) => {
  const [shouldRenderAdverts, setShouldRenderAdverts] = useState(
    isClientWidthAbove(minimumClientWidth),
  );
  const searchQuery = useSelector(selectSearchQuery);
  const location = useSelector(selectResultsLocation);
  const gptTargeting = useSelector(selectGPTTargeting);
  const { locale } = useAppConfig();
  const [memorizedTargetingData, setMemorizedTargetingData] =
    useState<TargetingData>(() => {
      if (gptTargeting) {
        return clean(gptTargeting);
      }
      return searchTargeting(searchQuery, locale, location);
    });

  useEffect(() => {
    let newTargetingData = [];
    if (gptTargeting) {
      newTargetingData = clean(gptTargeting);
    } else {
      newTargetingData = searchTargeting(searchQuery, locale, location);
    }

    if (
      JSON.stringify(newTargetingData) !==
      JSON.stringify(memorizedTargetingData)
    ) {
      setShouldRenderAdverts(false);
      setMemorizedTargetingData(newTargetingData);
    }
  }, [location, memorizedTargetingData, searchQuery, locale, gptTargeting]);

  useEffect(() => {
    if (isClientWidthAbove(minimumClientWidth) && !shouldRenderAdverts) {
      setShouldRenderAdverts(true);
    }
  }, [shouldRenderAdverts, minimumClientWidth]);

  return {
    shouldRenderAdverts,
    targetingData: memorizedTargetingData,
  };
};

export default useHoustonAd;
