import { useTranslations } from '@vocab/react';
import { Box, Stack, Heading, Text } from 'braid-design-system';

import translations from './.vocab';
// @ts-expect-error
import successImage from './success.png';

const UpToDateZeroCount = () => {
  const { t } = useTranslations(translations);

  return (
    <>
      <Box width="full" marginTop={{ mobile: 'xxlarge', desktop: 'xxxlarge' }}>
        <Box
          justifyContent="center"
          id="newbox"
          display="flex"
          width="full"
          maxWidth="large"
        >
          <Stack space="large" align="center">
            <Box
              style={{
                background: `url('${successImage}')`,
                width: '128px',
                height: '128px',
                backgroundSize: 'cover',
              }}
            />
            <Box maxWidth="xsmall" textAlign="center">
              <Heading level="3" weight="regular">
                {t("You're up to date!")}
              </Heading>

              <Box style={{ marginTop: '32px' }}>
                <Text size="small" tone="secondary">
                  {t('No new jobs for this search')}
                </Text>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default UpToDateZeroCount;
