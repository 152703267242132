import { useTranslations } from '@vocab/react';
import { IconChevron, Text, TextLinkButton } from 'braid-design-system';

import translations from './.vocab';

export const ToggleButton = ({
  isCollapsed,
  onClick,
}: {
  isCollapsed: boolean;
  onClick: () => void;
}) => {
  const { t } = useTranslations(translations);
  return (
    <Text size="small">
      <TextLinkButton
        onClick={onClick}
        tabIndex={-1}
        weight="weak"
        icon={<IconChevron direction={isCollapsed ? 'down' : 'up'} />}
        iconPosition="trailing"
      >
        {isCollapsed ? t('Read More') : t('Read Less')}
      </TextLinkButton>
    </Text>
  );
};
