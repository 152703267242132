import { useTranslations } from '@vocab/react';
import { Heading } from 'braid-design-system';

import translations from './.vocab';
interface CompanySummaryProps {
  companyName: string;
}

const CompanySummary = ({ companyName }: CompanySummaryProps) => {
  const { t } = useTranslations(translations);

  return (
    <Heading
      level="4"
      component="span"
      data={{ automation: 'company-serp-job-at' }}
    >
      {t('Jobs at {companyName}', { companyName })}
    </Heading>
  );
};

export default CompanySummary;
