import type { JobCardProps } from '@seek/job-card';
import { useCallback } from 'react';

import isNewTabEvent from 'src/modules/is-new-tab-event';

export function useJobLinkOnClickCallback({
  jobDetailPageLink: { onBeforeNavigation },
  onClick,
}: Pick<JobCardProps, 'onClick' | 'jobDetailPageLink'>) {
  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      onBeforeNavigation?.();
      if (!isNewTabEvent(e)) {
        onClick?.(e);
      }
    },
    [onBeforeNavigation, onClick],
  );
}
