
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiU2hvdyBhbGwgSm9icyIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSI6IlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiVGFtcGlsa2FuIHNlbXVhIExvd29uZ2FuIEtlcmphIiwiU2hvd2luZyBqb2JzIGZvciB7Y29tcGFueU5hbWV9IjoiTWVuYW1waWxrYW4gbG93b25nYW4ga2VyamEgZGkge2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJNZW5hbXBpbGthbiBsb3dvbmdhbiBrZXJqYSBkaSB7Y29tcGFueU5hbWVDb3VudH0gcGVydXNhaGFhbi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiVGFtcGlsa2FuIHNlbXVhIExvd29uZ2FuIEtlcmphIiwiU2hvd2luZyBqb2JzIGZvciB7Y29tcGFueU5hbWV9IjoiTWVuYW1waWxrYW4gbG93b25nYW4ga2VyamEgZGkge2NvbXBhbnlOYW1lfSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJNZW5hbXBpbGthbiBsb3dvbmdhbiBrZXJqYSBkaSB7Y29tcGFueU5hbWVDb3VudH0gcGVydXNhaGFhbi4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoi4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiU2hvd2luZyBqb2JzIGZvciB7Y29tcGFueU5hbWV9Ijoi4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiC4Lit4LiHIHtjb21wYW55TmFtZX0iLCJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIjoi4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4Liq4Liz4Lir4Lij4Lix4LiaIHtjb21wYW55TmFtZUNvdW50fSDguJrguKPguLTguKnguLHguJcifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoi4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiU2hvd2luZyBqb2JzIGZvciB7Y29tcGFueU5hbWV9Ijoi4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiC4Lit4LiHIHtjb21wYW55TmFtZX0iLCJTaG93aW5nIGpvYnMgZm9yIHtjb21wYW55TmFtZUNvdW50fSBjb21wYW5pZXMuIjoi4LmB4Liq4LiU4LiH4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4Liq4Liz4Lir4Lij4Lix4LiaIHtjb21wYW55TmFtZUNvdW50fSDguJrguKPguLTguKnguLHguJcifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiW8Wg4bipw7bDtsO2xbUgxIPEg8SDxprGmiDEtMO2w7bDtsOfxaFdIiwiU2hvd2luZyBqb2JzIGZvciB7Y29tcGFueU5hbWV9IjoiW8Wg4bipw7bDtsO2xbXDrMOsw6zguIHguLXguYnEoyDEtcO2w7bDtsOfxaEgxpLDtsO2w7bFmSB7Y29tcGFueU5hbWV9XSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJbxaDhuKnDtsO2w7bFtcOsw6zDrOC4geC4teC5icSjIMS1w7bDtsO2w5/FoSDGksO2w7bDtsWZIHtjb21wYW55TmFtZUNvdW50fSDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw6zDrMOs4bq94bq94bq9xaEuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IGFsbCBKb2JzIjoiW8Wg4bipw7bDtsO2xbUgxIPEg8SDxprGmiDEtMO2w7bDtsOfxaFdIiwiU2hvd2luZyBqb2JzIGZvciB7Y29tcGFueU5hbWV9IjoiW8Wg4bipw7bDtsO2xbXDrMOsw6zguIHguLXguYnEoyDEtcO2w7bDtsOfxaEgxpLDtsO2w7bFmSB7Y29tcGFueU5hbWV9XSIsIlNob3dpbmcgam9icyBmb3Ige2NvbXBhbnlOYW1lQ291bnR9IGNvbXBhbmllcy4iOiJbxaDhuKnDtsO2w7bFtcOsw6zDrOC4geC4teC5icSjIMS1w7bDtsO2w5/FoSDGksO2w7bDtsWZIHtjb21wYW55TmFtZUNvdW50fSDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw6zDrMOs4bq94bq94bq9xaEuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    