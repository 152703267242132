import { type MouseEvent, useCallback, useMemo } from 'react';

import { useEnrichedLocation } from 'src/modules/enriched-location/index.ts';
import { useDispatch, useSelector } from 'src/store/react';
import { selectAuthenticated, selectSelectedJobId } from 'src/store/selectors';
import { getViewJobOriginRef } from 'src/utils/eventCapture/eventCaptureUtils.ts';

import { useAnalyticsFacade } from '../../modules/AnalyticsFacade';
import { linkNavigate } from '../../store/location/index.ts';
import { useReturnUrl } from '../useReturnUrl.ts';

import { useSavedJobsDataSERP } from './utils/useSavedJobsData.ts';
import {
  type UseToggleSaveJobProps,
  useToggleSavedJob,
} from './utils/useToggleSavedJob';

const noop = () => {};

export const useSaveJobSERP = ({
  jobId,
  jobTracking,
  solMetadataReference,
}: Pick<
  UseToggleSaveJobProps,
  'jobId' | 'jobTracking' | 'solMetadataReference'
>) => {
  const dispatch = useDispatch();
  const analyticsFacade = useAnalyticsFacade();
  const selectedJobId = useSelector(selectSelectedJobId);
  const isAuthenticated = useSelector(selectAuthenticated);
  const searchSavedAndAppliedJob = useSavedJobsDataSERP();

  const linkPosition = 'listing';

  const {
    toggleSavedJob: sharedToggleSavedJob,
    isApplied,
    isSaved,
  } = useToggleSavedJob({
    jobId,
    view: 'serp',
    linkPosition,
    solMetadataReference,
    jobTracking,
    savedJobsData: searchSavedAndAppliedJob?.data,
  });

  const returnUrl = useReturnUrl({
    mode: 'signin',
    query: {
      savejob: jobId,
      jobId: selectedJobId,
    },
  });
  // We need to call useEnrichLocation for the linkNavigate action to work correctly
  // TextLink on the other hand will call useEnrichedLocation within NavLink flow
  const enrichedLocation = useEnrichedLocation({
    location: returnUrl,
  });

  const unauthenticatedToggleSavedJobInSplitView = useCallback(
    (event: MouseEvent) => {
      const viewJobOriginRef = getViewJobOriginRef();
      dispatch(
        linkNavigate({
          event,
          target: '_self',
          location: enrichedLocation,
          preNavigationHooks: [
            () => {
              analyticsFacade.saveJobClicked({
                jobId,
                linkPosition,
                jobViewOriginQuery: viewJobOriginRef,
              });
            },
          ],
        }),
      );
    },
    [dispatch, enrichedLocation, jobId, linkPosition, analyticsFacade],
  );

  const getToggleSavedJob = useMemo(
    () => (): ((event: MouseEvent) => void) => {
      if (isApplied) {
        return noop;
      } else if (!isAuthenticated) {
        return unauthenticatedToggleSavedJobInSplitView;
      }
      return sharedToggleSavedJob;
    },
    [
      isApplied,
      isAuthenticated,
      sharedToggleSavedJob,
      unauthenticatedToggleSavedJobInSplitView,
    ],
  );

  return {
    isApplied,
    isSaved,
    toggleSavedJob: getToggleSavedJob(),
    returnUrl,
  };
};
