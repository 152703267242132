import { useTranslations } from '@vocab/react';
import { Heading, HiddenVisually, Text } from 'braid-design-system';

import translations from './.vocab';
import { useSEOBlurb } from './useSEOBlurb';

export const SEOBlurb = () => {
  const { t } = useTranslations(translations);
  const renderedBlurb = useSEOBlurb();
  return (
    <>
      <HiddenVisually>
        <Heading level="2">{t('Refine by sub-classifications')}</Heading>
      </HiddenVisually>
      <Text size="small">{renderedBlurb}</Text>
    </>
  );
};
