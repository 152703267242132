import { useTranslations } from '@vocab/react';

import { IllustratedError } from 'src/components/IllustratedError/IllustratedError';
import BriefcaseSvg from 'src/components/JobDetailsPage/Briefcase.svg';
import {
  useStopTimerOnComponentVisible,
  useStopTimerOnComponentVisibleControl,
} from 'src/modules/hooks/useStopTimerOnComponentVisible';

import translations from './.vocab';

export const JobExpired = () => {
  const { t } = useTranslations(translations);

  const ref = useStopTimerOnComponentVisible({
    actionName: 'JobListItemClickToJDV:Visible',
    additionalTags: ['error-outcome'],
  });

  useStopTimerOnComponentVisibleControl({
    actionName: 'JobListItemClickToJDV:Visible:Control',
    additionalTags: ['error-outcome'],
  });

  return (
    <>
      <IllustratedError
        ref={ref}
        heading={t('This job is no longer available')}
        description={t('Select another job or try a different search.')}
        Illustration={BriefcaseSvg}
        dataAutomation="JDVExpired"
      />
    </>
  );
};
