
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJZb3UncmUgdXAgdG8gZGF0ZSEiLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2gifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJBbmRhIHN1ZGFoIHVwIHRvIGRhdGUhIiwiTm8gbmV3IGpvYnMgZm9yIHRoaXMgc2VhcmNoIjoiVGlkYWsgYWRhIHBla2VyamFhbiBiYXJ1IHVudHVrIHBlbmNhcmlhbiBpbmkifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJBbmRhIHN1ZGFoIHVwIHRvIGRhdGUhIiwiTm8gbmV3IGpvYnMgZm9yIHRoaXMgc2VhcmNoIjoiVGlkYWsgYWRhIHBla2VyamFhbiBiYXJ1IHVudHVrIHBlbmNhcmlhbiBpbmkifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiLguITguLjguJPguK3guLHguJ7guYDguJTguJfguIfguLLguJnguYPguKvguKHguYjguYDguKPguLXguKLguJrguKPguYnguK3guKLguYHguKXguYnguKchIiwiTm8gbmV3IGpvYnMgZm9yIHRoaXMgc2VhcmNoIjoi4LmE4Lih4LmI4Lih4Li14LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiZ4Li14LmJIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiLguITguLjguJPguK3guLHguJ7guYDguJTguJfguIfguLLguJnguYPguKvguKHguYjguYDguKPguLXguKLguJrguKPguYnguK3guKLguYHguKXguYnguKchIiwiTm8gbmV3IGpvYnMgZm9yIHRoaXMgc2VhcmNoIjoi4LmE4Lih4LmI4Lih4Li14LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiZ4Li14LmJIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJbw53DtsO2w7bHmseax5onxZnhur3hur3hur0gx5rHmseaxqUg4bmvw7bDtsO2IMaMxIPEg8SD4bmv4bq94bq94bq9IV0iLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJbw5HDtsO2w7Yg4LiB4Li14LmJ4bq94bq94bq9xbUgxLXDtsO2w7bDn8WhIMaSw7bDtsO2xZkg4bmv4bipw6zDrMOsxaEgxaHhur3hur3hur3Eg8SDxIPFmcOn4bipXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJZb3UncmUgdXAgdG8gZGF0ZSEiOiJbw53DtsO2w7bHmseax5onxZnhur3hur3hur0gx5rHmseaxqUg4bmvw7bDtsO2IMaMxIPEg8SD4bmv4bq94bq94bq9IV0iLCJObyBuZXcgam9icyBmb3IgdGhpcyBzZWFyY2giOiJbw5HDtsO2w7Yg4LiB4Li14LmJ4bq94bq94bq9xbUgxLXDtsO2w7bDn8WhIMaSw7bDtsO2xZkg4bmv4bipw6zDrMOsxaEgxaHhur3hur3hur3Eg8SDxIPFmcOn4bipXSJ9!"
        )
      )
      });
  
      export { translations as default };
    