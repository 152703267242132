export default () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M76.816 76.5352L85.784 85.6472"
      stroke="#0D3880"
      strokeWidth="12.63"
      strokeMiterlimit="10"
    />
    <path
      d="M51.96 90.2394C51.856 90.2394 51.752 90.2394 51.648 90.2394C41.304 90.1594 31.616 86.0554 24.36 78.6874C17.104 71.3194 13.152 61.5594 13.232 51.2234C13.312 40.8794 17.416 31.1914 24.784 23.9354C32.152 16.6794 41.912 12.7434 52.248 12.8074C62.592 12.8874 72.28 16.9914 79.536 24.3594C94.512 39.5754 94.32 64.1354 79.112 79.1114C71.816 86.2954 62.184 90.2394 51.96 90.2394ZM51.944 22.9034C44.384 22.9034 37.264 25.8154 31.872 31.1274C26.424 36.4874 23.392 43.6554 23.336 51.2954C23.28 58.9354 26.2 66.1514 31.56 71.5994C36.92 77.0474 44.088 80.0794 51.728 80.1354C59.384 80.2154 66.584 77.2714 72.032 71.9114C83.28 60.8394 83.416 42.6874 72.344 31.4394C66.984 25.9914 59.816 22.9594 52.176 22.9034C52.096 22.9034 52.024 22.9034 51.944 22.9034Z"
      fill="#0D3880"
    />
    <path
      d="M76.4379 88.6793L88.6953 76.6129C89.0145 76.2986 89.5236 76.3026 89.8379 76.6219L112.068 99.204C115.71 102.904 115.664 108.866 111.964 112.508C108.264 116.151 102.301 116.104 98.659 112.404L76.4289 89.8219C76.1146 89.5026 76.1186 88.9935 76.4379 88.6793Z"
      fill="#EE399E"
    />
    <path
      d="M120.5 51.0227C120.284 51.0227 120.077 51.04 119.869 51.0573C119.135 40.672 110.486 32.4727 99.9196 32.4727C90.934 32.4727 83.3395 38.3911 80.808 46.5472C79.7712 46.193 78.6652 45.9943 77.5075 45.9943C71.8137 45.9943 67.2 50.608 67.2 56.3018C67.2 57.0794 67.295 57.8311 67.4592 58.5568H128.043C128.043 54.401 124.673 51.0227 120.509 51.0227H120.5Z"
      fill="#88E2E9"
    />
    <path
      d="M29.8893 103.538C29.1658 103.538 28.4787 103.684 27.8464 103.945C26.4966 97.5797 20.8483 92.8008 14.0813 92.8008C6.30496 92.8008 0 99.1057 0 106.882C0 107.545 0.04864 108.201 0.13984 108.84H35.191C35.191 105.915 32.8198 103.538 29.8893 103.538Z"
      fill="#88E2E9"
    />
  </svg>
);
