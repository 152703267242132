import { useTranslations } from '@vocab/react';
import {
  Box,
  Heading,
  IconArrow,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';

import translations from './.vocab';
import InitialViewIcon from './InitialViewIcon/InitialViewIcon';

import * as styles from './SplitViewInitialView.css';

const SplitViewInitialView = () => {
  const { t } = useTranslations(translations);
  return (
    <Box
      height="full"
      width="full"
      paddingX="xlarge"
      paddingY="xxlarge"
      data-automation="initialView"
      borderRadius="large"
      background="neutralSoft"
      position="absolute"
    >
      <Inline space="xxsmall">
        <IconArrow direction="left" />
        <Stack space="medium">
          <Heading level="3">{t('Select a job')}</Heading>
          <Text tone="secondary">{t('Display details here')}</Text>
        </Stack>
      </Inline>
      <Box className={styles.iconWrapper}>
        <InitialViewIcon />
      </Box>
    </Box>
  );
};
export default SplitViewInitialView;
