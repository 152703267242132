import type { SearchResultJob, SearchResultJobV5 } from '@seek/chalice-types';
import type { ComponentProps, ReactNode } from 'react';

import { AccessabilityLinks } from 'src/components/SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';
import { useSplitViewContext } from 'src/handlers/SplitViewContext';

import SearchResultList from '../SearchResultList/SearchResultList';

import JobDetail from './JobDetail/JobDetail';
import SplitViewLayout from './SplitViewLayout/SplitViewLayout';
import useSplitViewOpen1stJobAdEffect from './useSplitViewOpen1stJobAdEffect/useSplitViewOpen1stJobAdEffect';

interface SplitViewProps {
  jobs: SearchResultJob[] | SearchResultJobV5[];
  resultSummary: ReactNode;
  isCompanySearch: ComponentProps<typeof SearchResultList>['isCompanySearch'];
}

const SplitView = ({
  jobs,
  resultSummary,
  isCompanySearch,
}: SplitViewProps) => {
  const {
    selectedJob,
    setSelectedJob,
    currentJobDetails,
    fetchJobDetails,
    isLoading,
  } = useSplitViewContext();

  // job.id is a string in V5
  const selectedJobId =
    typeof selectedJob?.id === 'string'
      ? parseInt(selectedJob?.id, 10)
      : selectedJob?.id;

  useSplitViewOpen1stJobAdEffect(jobs);

  return (
    <SplitViewLayout
      ListView={
        <>
          {resultSummary}
          {jobs.length > 0 && (
            <SearchResultList
              jobs={jobs}
              isCompanySearch={isCompanySearch}
              setCurrentJob={setSelectedJob}
              selectedJobId={selectedJobId}
            />
          )}
        </>
      }
      DetailView={
        <>
          <AccessabilityLinks placement="job-details" position="top" />
          <JobDetail
            currentJobDetails={selectedJob ? currentJobDetails : null}
            fetchJobDetails={fetchJobDetails}
            isLoading={isLoading}
          />
          {selectedJob ? (
            <AccessabilityLinks placement="job-details" position="bottom" />
          ) : null}
        </>
      }
    />
  );
};

export default SplitView;
