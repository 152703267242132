import { useTranslations } from '@vocab/react';
import { Box, IconArrow, Inline, Text } from 'braid-design-system';
import type { MouseEvent } from 'react';

import { scrollTo } from 'src/modules/scroll-with-callback';
import { useSelector } from 'src/store/react';
import { selectResultsLocationDescription } from 'src/store/selectors';

import { NudgeTemplate } from '../NudgeTemplate/NudgeTemplate';

import translations from './.vocab';
import { IconLocationNearby } from './IconLocationNearby';

import * as styles from './DistanceFormNudge.css';

const scrollToRadialFilter = (event: MouseEvent) => {
  event.preventDefault();

  const searchSummaryElement = document.getElementById('searchResultSummary');

  if (searchSummaryElement === null) {
    return;
  }

  const yScroll =
    searchSummaryElement.getBoundingClientRect().top + window.scrollY;

  scrollTo({ top: yScroll });
};

const DistanceFromNudge = ({ position }: { position: number }) => {
  const locationDescription = useSelector(selectResultsLocationDescription);
  const { t } = useTranslations(translations);
  const ctaText = t('Take me there');

  return (
    <NudgeTemplate
      CTAs={[
        {
          Cta: () => (
            <Box
              aria-label={t('Choose preferred distance')}
              className={styles.cta}
              cursor="pointer"
              key="distance-nudge"
              data-automation="distance-nudge-cta"
              onClick={scrollToRadialFilter}
            >
              <Text tone="link" weight="medium">
                <Inline space="xsmall" alignY="center">
                  <>{ctaText}</>
                  <IconArrow direction="right" />
                </Inline>
              </Text>
            </Box>
          ),
          linkText: ctaText,
        },
      ]}
      data={{ automation: 'distance-nudge' }}
      heading={t('Looking for something nearby?')}
      Icon={IconLocationNearby}
      id="distance_nudge"
      nudgeType="Granular Location"
      position={position}
      showIconInMobile
      text={t('Choose your preferred distance surrounding {location}', {
        location: locationDescription,
      })}
    />
  );
};

export default DistanceFromNudge;
