import { useTranslations } from '@vocab/react';
import { Alert, Heading, Stack, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { useIsPDPAConsentRequired } from 'src/hooks/useIsPDPAConsentRequired';
import { useSelector } from 'src/store/react';
import { SavedSearchStatus } from 'src/store/saveSearch/types';
import {
  selectSaveSearchEmailAddress,
  selectSaveSearchStatus,
} from 'src/store/selectors';

import translations from './.vocab';
import UnregisteredSaveSearch from './UnregisteredSaveSearch';

const JobMailWrapper = ({
  position,
  inputRef,
}: ComponentProps<typeof UnregisteredSaveSearch>) => {
  const successfullySignedUp =
    useSelector(selectSaveSearchStatus) === SavedSearchStatus.SAVED;
  const savedEmailAddress = useSelector(selectSaveSearchEmailAddress);
  const isPDPAConsentRequired =
    useIsPDPAConsentRequired().isPDPAConsentRequired;

  const { t } = useTranslations(translations);

  if (successfullySignedUp) {
    return (
      <Alert tone="positive" data={{ automation: 'jobmail-success-message' }}>
        <Text>
          {t('Your saved search will be sent to {email}', {
            email: savedEmailAddress,
          })}
        </Text>
      </Alert>
    );
  }

  return (
    <Stack space="small" data={{ automation: `jobMailPanel-${position}` }}>
      {!successfullySignedUp && (
        <Heading level="4">
          {t('Receive new jobs for this search by email')}
        </Heading>
      )}

      <UnregisteredSaveSearch inputRef={inputRef} position={position} />

      {isPDPAConsentRequired ? (
        <Text size="small" data={{ automation: 'pdpa-consent' }}>
          {t(
            'You confirm that you’re at least 20 years old or have parental consent for Jobsdb and affiliates to process your personal data.',
          )}
        </Text>
      ) : null}
    </Stack>
  );
};

export default JobMailWrapper;
