export const IconLocationNearby = () => (
  <svg
    width="96"
    height="98"
    viewBox="0 0 96 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3078_11421)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9597 43.313H16V34.2336H37.9597C47.771 34.2336 55.7246 42.1873 55.7246 51.9985C55.7246 61.8098 47.771 69.7635 37.9597 69.7635H34.6703C29.8734 69.7635 25.9847 73.6521 25.9847 78.449V79.3793C25.9847 84.1762 29.8734 88.0648 34.6703 88.0648H80V97.1442H34.6703C24.859 97.1442 16.9054 89.1906 16.9054 79.3793V78.449C16.9054 68.6377 24.859 60.6841 34.6703 60.6841H37.9597C42.7566 60.6841 46.6453 56.7955 46.6453 51.9985C46.6453 47.2017 42.7566 43.313 37.9597 43.313Z"
        fill="#DCE5F2"
      />
      <path
        d="M32.9224 16.9612C32.9224 30.1302 16.4612 43.2991 16.4612 43.2991C16.4612 43.2991 0 30.1302 0 16.9612C0 7.86993 7.36994 0.5 16.4612 0.5C25.5525 0.5 32.9224 7.86994 32.9224 16.9612Z"
        fill="#0D3880"
      />
      <path
        d="M16.4612 23.5459C20.0977 23.5459 23.0457 20.5979 23.0457 16.9614C23.0457 13.3249 20.0977 10.377 16.4612 10.377C12.8247 10.377 9.87671 13.3249 9.87671 16.9614C9.87671 20.5979 12.8247 23.5459 16.4612 23.5459Z"
        fill="white"
      />
      <path
        d="M96.0001 70.1624C96.0001 83.3314 79.5388 96.5003 79.5388 96.5003C79.5388 96.5003 63.0776 83.3314 63.0776 70.1624C63.0776 61.0711 70.4476 53.7012 79.5388 53.7012C88.6301 53.7012 96.0001 61.0711 96.0001 70.1624Z"
        fill="#EE399E"
      />
      <path
        d="M79.5388 76.7471C83.1753 76.7471 86.1233 73.7991 86.1233 70.1626C86.1233 66.5261 83.1753 63.5781 79.5388 63.5781C75.9023 63.5781 72.9543 66.5261 72.9543 70.1626C72.9543 73.7991 75.9023 76.7471 79.5388 76.7471Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3078_11421">
        <rect
          width="96.0001"
          height="97"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
