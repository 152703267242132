
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiUHJldiIsIk5leHQiOiJOZXh0IiwiUGFnaW5hdGlvbiBvZiByZXN1bHRzIjoiUGFnaW5hdGlvbiBvZiByZXN1bHRzIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiU2ViZWx1bW55YSIsIk5leHQiOiJTZWxhbmp1dG55YSIsIlBhZ2luYXRpb24gb2YgcmVzdWx0cyI6IlBlbm9tb3JhbiBoYWxhbWFuIGhhc2lsIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiU2ViZWx1bW55YSIsIk5leHQiOiJTZWxhbmp1dG55YSIsIlBhZ2luYXRpb24gb2YgcmVzdWx0cyI6IlBlbm9tb3JhbiBoYWxhbWFuIGhhc2lsIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2Ijoi4LiB4LmI4Lit4LiZ4Lir4LiZ4LmJ4LiyIiwiTmV4dCI6IuC4luC4seC4lOC5hOC4myIsIlBhZ2luYXRpb24gb2YgcmVzdWx0cyI6IuC4q+C4meC5ieC4suC4nOC4peC4peC4seC4nuC4mOC5jCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2Ijoi4LiB4LmI4Lit4LiZ4Lir4LiZ4LmJ4LiyIiwiTmV4dCI6IuC4luC4seC4lOC5hOC4myIsIlBhZ2luYXRpb24gb2YgcmVzdWx0cyI6IuC4q+C4meC5ieC4suC4nOC4peC4peC4seC4nuC4mOC5jCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiW8akxZnhur3hur3hur3hub1dIiwiTmV4dCI6IlvDkeG6veG6veG6veqtleG5r10iLCJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJbxqTEg8SDxIPEo8Osw6zDrOC4geC4teC5icSDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDDtsO2w7bGkiDFmeG6veG6veG6vcWhx5rHmseaxprhua/FoV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcmV2IjoiW8akxZnhur3hur3hur3hub1dIiwiTmV4dCI6IlvDkeG6veG6veG6veqtleG5r10iLCJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJbxqTEg8SDxIPEo8Osw6zDrOC4geC4teC5icSDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDDtsO2w7bGkiDFmeG6veG6veG6vcWhx5rHmseaxprhua/FoV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    