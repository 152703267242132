import { useTranslations } from '@vocab/react';

import { IllustratedError } from 'src/components/IllustratedError/IllustratedError';
import Tools from 'src/components/IllustratedError/Tools';

import translations from './.vocab';

const ResultsError = () => {
  const { t } = useTranslations(translations);
  return (
    <IllustratedError
      heading={t("We're working on it.")}
      description={
        <>
          {t(
            "We can't load this right now but we're doing our best to fix it.",
          )}
          <br />
          {t('Try refreshing the page or check back later.')}
        </>
      }
      Illustration={Tools}
    />
  );
};

export default ResultsError;
