export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="102" height="76" fill="none">
    <path
      fill="#EAF0FA"
      d="M82.416 40.804c0-9.483-7.776-17.232-17.313-17.232H23.269c-9.51 0-17.314 7.75-17.314 17.232v25.144C5.955 71.476 10.507 76 16.062 76H72.31c5.554 0 10.106-4.525 10.106-10.052V40.805Z"
    />
    <path
      fill="#0D1F43"
      d="M44.267 55.517c-2.736 0-4.985-2.222-4.985-4.959 0-2.736 2.221-4.958 4.985-4.958 2.764 0 4.985 2.222 4.985 4.959 0 2.736-2.221 4.958-4.985 4.958Zm0-8.48a3.515 3.515 0 0 0-3.522 3.522 3.533 3.533 0 0 0 3.522 3.522 3.533 3.533 0 0 0 3.522-3.523 3.533 3.533 0 0 0-3.522-3.522Z"
    />
    <path
      fill="#0D1F43"
      d="M39.85 51.209c-19.047-.434-30.291-3.685-35.304-5.582C2.243 44.733.7 42.457.7 39.991V19.888c0-4.714 3.847-8.562 8.589-8.562h20.836V6.883c0-3.793 3.116-6.91 6.936-6.91h14.495c3.82 0 6.937 3.09 6.937 6.91v5.175a.728.728 0 0 1-.732.732H9.288c-3.929 0-7.153 3.197-7.153 7.098v20.105c0 1.842 1.192 3.603 2.926 4.28 4.904 1.87 15.986 5.04 34.817 5.474.406 0 .704.352.704.731 0 .407-.325.705-.731.705v.027Zm-8.29-39.856h25.469V6.909c0-3.007-2.466-5.446-5.474-5.446H37.06c-3.035 0-5.473 2.439-5.473 5.446v4.444h-.027ZM48.738 51.209a.74.74 0 0 1-.732-.705c0-.406.298-.731.705-.731 4.958-.136 9.916-.46 15.145-1.084.38-.054.759.244.786.623a.69.69 0 0 1-.623.786c-5.284.596-10.269.948-15.281 1.084v.027ZM78.948 32.785a2.613 2.613 0 0 1-2.628-2.629V14.577a2.648 2.648 0 0 1 2.628-2.628c1.436 0 2.682 1.165 2.682 2.628v15.58c-.081 1.517-1.246 2.628-2.682 2.628Zm.054-19.454c-.759 0-1.328.569-1.328 1.273v15.58c0 .731.542 1.273 1.274 1.273.731 0 1.3-.57 1.328-1.3v-15.58c0-.677-.597-1.273-1.274-1.273v.027ZM78.948 40.56a2.613 2.613 0 0 1-2.628-2.628 2.63 2.63 0 0 1 2.628-2.628 2.63 2.63 0 0 1 2.628 2.628 2.63 2.63 0 0 1-2.628 2.628Zm0-3.901a1.272 1.272 0 1 0 0 2.547c.704 0 1.273-.57 1.273-1.274 0-.704-.569-1.273-1.273-1.273Z"
    />
    <path
      fill="#0D1F43"
      d="M78.948 48.526c-12.328 0-22.353-9.998-22.353-22.271C56.595 13.98 66.62 4.01 78.948 4.01c12.328 0 22.353 9.998 22.353 22.272S91.276 48.553 78.948 48.553v-.027Zm0-43.161c-11.57 0-20.998 9.374-20.998 20.917 0 11.542 9.429 20.917 20.998 20.917 11.57 0 20.998-9.375 20.998-20.917 0-11.543-9.429-20.917-20.998-20.917Z"
    />
  </svg>
);
