import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

import * as styles from './SEOFooterRowLayout.css';

interface SEOFooterRowLayoutProps {
  automation?: string;
  content: ReactNode;
  hidden?: boolean;
  label?: ReactNode;
}

export const SEOFooterRowLayout = ({
  automation,
  content,
  hidden,
  label,
}: SEOFooterRowLayoutProps) => (
  <Box
    className={styles.row}
    data-automation={automation}
    display={hidden ? 'none' : 'flex'}
    justifyContent="flexStart"
  >
    {label && (
      <Box marginRight="gutter" className={styles.label}>
        {label}
      </Box>
    )}
    <Box className={styles.content}>{content}</Box>
  </Box>
);
