const states = {
  'Australian Capital Territory': 'act',
  'New South Wales': 'nsw',
  'Northern Territories': 'nt',
  Queensland: 'qld',
  'South Australia': 'sa',
  Tasmania: 'tas',
  Victoria: 'vic',
  'Western Australia': 'wa',
  'New Zealand': 'nz',
  'UK & Ireland': 'uk',
  Overseas: 'overseas',
} as const;

export type StateKeys = keyof typeof states;

export default states;
