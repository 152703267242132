import { useMutation } from '@apollo/client';
import { metrics } from '@seek/metrics-js';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  IconArrow,
  Inline,
  Text,
  TextLink,
} from 'braid-design-system';
import { useCallback } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { AddSalaryNudgeFeedbackDocument } from 'src/graphql/graphql.ts';
import { useBrandName } from 'src/hooks/useBrandName';
import { logger } from 'src/modules/logger';
import {
  dismissNudge as dismissNudgeDispatch,
  nudgeMetricsPrefix,
} from 'src/store/nudges';
import {
  ADD_NUDGE_FEEDBACK_ERROR,
  ADD_NUDGE_FEEDBACK_SUCCESS,
} from 'src/store/nudges/types';
import { useDispatch } from 'src/store/react';

import { type NudgeCTA, NudgeTemplate } from '../NudgeTemplate/NudgeTemplate';

import translations from './.vocab';
import { IconProfileSuccess } from './icons';
import { useProfileNudge } from './useProfileNudge';

import * as styles from './ProfileNudge.css';

const PROFILE_LINK = '/profile/me';
const getProfileLinkCTA = (text: string): NudgeCTA => ({
  Cta: () => (
    <Box className={styles.profileLinkContainer}>
      <Text weight="medium" tone="link" size="standard">
        <TextLink
          data-automation="profile-nudge-profile-link"
          href={PROFILE_LINK}
          weight="regular"
        >
          <Inline space="xsmall" alignY="center">
            <>{text}</>
            <IconArrow direction="right" />
          </Inline>
        </TextLink>
      </Text>
    </Box>
  ),
  href: PROFILE_LINK,
  linkText: text,
});

export const ProfileNudge = ({ position }: { position: number }) => {
  const dispatch = useDispatch();
  const { t } = useTranslations(translations);
  const {
    heading,
    Icon,
    nudge,
    nudgeType,
    shouldFireMetrics,
    shouldShowError,
    shouldShowThankYouMessage,
    subHeading,
    text,
  } = useProfileNudge();
  const brandName = useBrandName();
  const { zone } = useAppConfig();
  const dispatchDismissNudge = useCallback(() => {
    dispatch(dismissNudgeDispatch());
  }, [dispatch]);

  const [addSalaryNudgeFeedback] = useMutation(AddSalaryNudgeFeedbackDocument, {
    onError: (error) => {
      metrics.count(nudgeMetricsPrefix, ['userresponded:error']);
      logger.error({ error, zone }, 'AddSalaryNudgeFeedback GraphQL Error');
      dispatch({ type: ADD_NUDGE_FEEDBACK_ERROR });
    },
    onCompleted: () => {
      metrics.count(nudgeMetricsPrefix, ['userresponded:success']);
      dispatch({ type: ADD_NUDGE_FEEDBACK_SUCCESS });
    },
  });

  if (!nudge) {
    return null;
  }

  if (!(nudge || shouldShowThankYouMessage)) {
    return null;
  }

  const dismissNudge = ({ nudgeId }: { nudgeId: string }) => {
    dispatchDismissNudge();

    if (!shouldShowThankYouMessage && !shouldShowError) {
      addSalaryNudgeFeedback({
        variables: { nudgeId, nudgeOption: 'Dismiss', zone },
      });
    }
  };

  const profileLinkCTA = getProfileLinkCTA(t('Go to profile'));
  if (shouldShowError) {
    return (
      <NudgeTemplate
        CTAs={[profileLinkCTA]}
        data={{ automation: 'error-nudge' }}
        errorNudgeType={nudgeType}
        heading={t('There’s something wrong on our end.')}
        id="error"
        nudgeType="Error"
        onDismiss={dispatchDismissNudge}
        position={position}
        text={t('We weren’t able to save your answer to your profile.')}
      />
    );
  }

  const { callToActions, firstName, id } = nudge;
  if (shouldShowThankYouMessage) {
    return (
      <NudgeTemplate
        CTAs={[profileLinkCTA]}
        data={{ automation: 'thank-you-nudge' }}
        heading={t('Thanks {name}!', { name: firstName })}
        Icon={IconProfileSuccess}
        id="thank_you"
        nudgeType="Thank You"
        onDismiss={dispatchDismissNudge}
        position={position}
        text={t('We’ve updated your {brand} Profile.', { brand: brandName })}
      />
    );
  }

  const CTAs: NudgeCTA[] = callToActions.map(
    ({ operation: value, displayText }, index) => ({
      Cta: () => (
        <Button
          data={{ automation: 'profile-nudge-cta' }}
          key={value}
          onClick={() => {
            addSalaryNudgeFeedback({
              variables: {
                nudgeOption: value,
                nudgeId: id,
                zone,
              },
            });
          }}
          size="small"
          tone="neutral"
          variant={index === 0 ? 'ghost' : 'transparent'}
        >
          {displayText}
        </Button>
      ),
      linkText: displayText,
    }),
  );

  return (
    <NudgeTemplate
      CTAs={CTAs}
      data={{ automation: 'profile-nudge' }}
      heading={heading}
      Icon={Icon}
      id={id}
      nudgeType={nudgeType}
      onDismiss={() => dismissNudge({ nudgeId: id })}
      position={position}
      shouldFireMetrics={shouldFireMetrics}
      shouldImpressNudge
      subHeading={subHeading}
      text={text}
    />
  );
};

export default ProfileNudge;
