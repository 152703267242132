import { useTranslations } from '@vocab/react';
import type { ComponentType } from 'react';

import type { NudgeType } from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';

import translations from './.vocab';
import { IconWorkingRights, IconSalary } from './icons';

const getProfileNudgeType = (prompt?: string): NudgeType => {
  // There's currently no way to know the nudge type.
  // Relying on nudge prompt text to decide for now.
  const nudgePrompt = prompt?.toLowerCase() || '';
  if (nudgePrompt.includes('salary')) {
    return 'Salary';
  } else if (nudgePrompt.includes('work')) {
    return 'Right To Work';
  }
  return 'Unknown';
};

const nudgeTypeIconMap: Partial<Record<NudgeType, ComponentType>> = {
  'Right To Work': IconWorkingRights,
  Salary: IconSalary,
};

const mapToNudgeTextElements = ({
  currentNudgeIndex,
  welcomeText,
  prompt,
  text,
}: {
  currentNudgeIndex: number;
  welcomeText: string;
  prompt?: string;
  text?: string;
}): {
  heading?: string;
  subHeading?: string;
  text?: string;
} =>
  currentNudgeIndex === 0
    ? {
        heading: welcomeText,
        subHeading: prompt,
        text,
      }
    : {
        heading: prompt,
        text,
      };
export const useProfileNudge = () => {
  const { t } = useTranslations(translations);

  return useSelector(
    ({
      nudges: {
        currentNudgeIndex,
        nudgeFeedbackError,
        nudges,
        nudgesCompleted,
      },
    }) => {
      const nudge = nudges[currentNudgeIndex];

      const nudgeType = getProfileNudgeType(nudge?.prompt);
      const { heading, subHeading, text } = mapToNudgeTextElements({
        currentNudgeIndex,
        welcomeText: t('Hi {name},', { name: nudge?.firstName }),
        prompt: nudge?.prompt,
        text: nudge?.text,
      });

      return {
        heading,
        Icon: nudgeTypeIconMap[nudgeType],
        nudge,
        nudgeType,
        // We should only fire count metrics for the first time the nudges show ..
        // .. and not for every single nudge view
        shouldFireMetrics: currentNudgeIndex === 0,
        shouldShowError: nudgeFeedbackError,
        shouldShowThankYouMessage: nudgesCompleted,
        subHeading,
        text,
      };
    },
  );
};
