import type {
  SearchParams,
  SearchLocation,
  SearchResultLocationV5,
} from '@seek/chalice-types';
import { getClassification } from '@seek/classification-data';
import type { Locale } from '@seek/melways-sites';
import endsWith from 'lodash/endsWith';

import states, { type StateKeys } from './states';
import worktypes from './worktypes';

const locWordList = [
  'all',
  'nsw',
  'vic',
  'sa',
  'nt',
  'wa',
  'qld',
  'tas',
  'act',
  'nz',
];

const getTargetValue = (value = '') => {
  if (/^[\w\d\s.,&/>()'-]+$/.test(value)) {
    return value.replace(/[^a-z\d]/gi, '').toLowerCase();
  }
  return value;
};

const splitTargetValues = (values: string, token: any) =>
  values
    .split(token)
    .map((value) => getTargetValue(value))
    .filter((i) => i);

const removeWord = (word: string) => locWordList.indexOf(word) < 0;

const removeWords = (value: string) => {
  const valueLC = value.toLowerCase();
  const valueArray = valueLC.split(' ');

  return valueArray.filter(removeWord).join('');
};

export type TargetingData = Array<{ key: string; value: string | string[] }>;

export const searchTargeting = (
  searchQuery: SearchParams,
  locale: Locale,
  location?: SearchLocation | SearchResultLocationV5,
) => {
  const targeting: TargetingData = [];
  const {
    classification,
    subclassification,
    keywords,
    worktype,
    salarytype,
    salaryrange,
    advertiserid,
    companyid,
    graduatesearch,
  } = searchQuery;
  const {
    locationDescription,
    type: locationType,
    description: suburbDescription,
    whereId,
  } = location || {};
  targeting.push({ key: 'locale', value: locale });

  const areaDescription =
    location && 'areaDescription' in location ? location.areaDescription : '';
  const stateDescription =
    location && 'stateDescription' in location ? location.stateDescription : '';

  if (keywords && getTargetValue(keywords)) {
    targeting.push({ key: 'kw', value: getTargetValue(keywords) });
  }

  if (classification) {
    const industries = classification
      .split(',')
      .reduce<string[]>((acc, cId) => {
        const cls = getClassification(Number(cId));

        if (cls) {
          acc.push(getTargetValue(cls.name()));
        }

        return acc;
      }, []);

    if (industries.length) {
      targeting.push({ key: 'ind', value: industries });
    }
  }

  if (subclassification) {
    const occupations = subclassification
      .split(',')
      .reduce<string[]>((acc, sId) => {
        const subCls = getClassification(Number(sId));

        if (subCls) {
          acc.push(getTargetValue(subCls.name()));
        }

        return acc;
      }, []);

    if (occupations.length) {
      targeting.push({ key: 'occ', value: occupations });
    }
  }

  if (worktype) {
    const worktypeValues: string[] = worktype.split(',').map(
      (wId) =>
        // @ts-ignore
        worktypes[wId],
    );

    targeting.push({ key: 'wt', value: worktypeValues });
  }

  const getSalaryKey = (type?: string) => {
    switch (type) {
      case 'hourly':
        return 'HourlySal';
      case 'monthly':
        return 'MonthlySal';
      default:
        return 'AnnualSal';
    }
  };

  if (salaryrange) {
    const salaryKey = getSalaryKey(salarytype);
    const values = splitTargetValues(salaryrange, '-');

    if (values.length) {
      targeting.push({ key: salaryKey, value: values });
    }
  }

  if (advertiserid) {
    const values = splitTargetValues(advertiserid.toString(), ',');

    if (values.length) {
      targeting.push({ key: 'advertiser', value: values });
    }
  }

  if (companyid) {
    const values = splitTargetValues(companyid, ',');

    if (values.length) {
      targeting.push({ key: 'company', value: values });
    }
  }

  if (graduatesearch) {
    targeting.push({ key: 'grad', value: 'true' });
  }

  // Location
  if (whereId === 90000) {
    targeting.push({ key: 'loc', value: 'wfh' });

    return targeting;
  }

  const getStateValue = (state: string) => {
    if (state in states) {
      return states[state as StateKeys];
    }
    return getTargetValue(stateDescription);
  };

  if (stateDescription) {
    targeting.push({
      key: 'sta',
      value: getStateValue(stateDescription),
    });
  }

  if (locationDescription && getTargetValue(locationDescription)) {
    targeting.push({ key: 'loc', value: getTargetValue(locationDescription) });
  }

  if (areaDescription && getTargetValue(areaDescription)) {
    targeting.push({ key: 'area', value: getTargetValue(areaDescription) });
  }

  if (suburbDescription && locationType === 'Suburb') {
    let suburbName = suburbDescription;

    if (
      endsWith(suburbDescription, ' AU') ||
      endsWith(suburbDescription, ' NZ')
    ) {
      suburbName = suburbDescription.slice(0, -3);
    }

    if (getTargetValue(suburbName)) {
      targeting.push({ key: 'suburb', value: getTargetValue(suburbName) });
    }
  }

  if (
    suburbDescription &&
    locationType === 'Location' &&
    !locationDescription
  ) {
    /*  The search API is no longer sending locationDesription
     *  obviously this is something we need to chase up, but as a quick
     *  fix for the display adverts team I'm forcing all locations to get
     *  a loc value from description property of a location.
     *  See Dave De Run for more info */
    const description = removeWords(suburbDescription);

    if (getTargetValue(description)) {
      targeting.push({ key: 'loc', value: getTargetValue(description) });
    }
    return targeting;
  }

  return targeting;
};
