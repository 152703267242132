import { generateJobTitleLinkId } from '@seek/job-card';
import { useTranslations } from '@vocab/react';
import { HiddenVisually, TextLinkButton } from 'braid-design-system';
import { useCallback, type ComponentProps } from 'react';

import { KEYWORD_INPUT_FIELD_ID } from 'src/components/Search/SearchBar/KeywordField/KeywordField';
import { useSelector } from 'src/store/react';
import { selectResults, selectSelectedJobId } from 'src/store/selectors';

import translations from './.vocab';

const RETURN_TO_SEARCH_ACCESSABILITY_LINK_ID =
  'return-to-search-results-a11y-link';
const MODIFY_MY_SEARCH_ACCESSABILITY_LINK_ID = 'modify-my-search-a11y-link';

const generateA11yLinkId = ({
  placement,
  position,
  prefix,
}: Partial<AccessabilityLinkProps> & { prefix: string }) =>
  `${prefix}${placement ? `-${placement}` : ''}${
    position ? `-${position}` : ''
  }`;

export const useSplitViewAccessibilityLinks = () => {
  const currentSelectedJobId = useSelector(selectSelectedJobId);
  const firstJobId = useSelector(selectResults)?.jobs?.[0]?.id;
  const potentialTargetJobId = currentSelectedJobId || firstJobId;
  const focusSelectedJob = useCallback(() => {
    if (potentialTargetJobId) {
      document
        .getElementById(generateJobTitleLinkId(potentialTargetJobId))
        ?.focus();
    }
  }, [potentialTargetJobId]);

  const focusKeywordInput = useCallback(
    () => document.getElementById(KEYWORD_INPUT_FIELD_ID)?.focus(),
    [],
  );

  const focusReturnToSearchLink = useCallback(
    () =>
      document
        .getElementById(
          generateA11yLinkId({
            position: 'top',
            prefix: RETURN_TO_SEARCH_ACCESSABILITY_LINK_ID,
          }),
        )
        ?.focus({ preventScroll: true }),
    [],
  );

  return {
    focusReturnToSearchLink,
    focusKeywordInput,
    focusSelectedJob,
  };
};

const AccessabilityLink = ({
  id,
  onClick,
  text,
}: {
  id: string;
  text: string;
  onClick: ComponentProps<typeof TextLinkButton>['onClick'];
}) => (
  <HiddenVisually>
    <TextLinkButton
      id={id}
      data={{ automation: id }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.(e);
      }}
    >
      {text}
    </TextLinkButton>
  </HiddenVisually>
);

const ReturnToSearchResultsAccessabilityLink = ({
  position,
}: Pick<AccessabilityLinkProps, 'position'>) => {
  const { focusSelectedJob } = useSplitViewAccessibilityLinks();
  const { t } = useTranslations(translations);

  return (
    <AccessabilityLink
      id={generateA11yLinkId({
        position,
        prefix: RETURN_TO_SEARCH_ACCESSABILITY_LINK_ID,
      })}
      onClick={focusSelectedJob}
      text={t('Return to search results')}
    />
  );
};

export const ModifyMySearchAccessabilityLink = ({
  placement,
  position,
}: AccessabilityLinkProps) => {
  const { focusKeywordInput } = useSplitViewAccessibilityLinks();
  const { t } = useTranslations(translations);

  return (
    <AccessabilityLink
      id={generateA11yLinkId({
        placement,
        position,
        prefix: MODIFY_MY_SEARCH_ACCESSABILITY_LINK_ID,
      })}
      onClick={focusKeywordInput}
      text={t('Modify my search')}
    />
  );
};

interface AccessabilityLinkProps {
  position: 'top' | 'bottom';
  placement: 'job-list' | 'job-details';
}

export const AccessabilityLinks = ({
  position,
  placement,
}: AccessabilityLinkProps) => (
  <>
    <ReturnToSearchResultsAccessabilityLink position={position} />
    <ModifyMySearchAccessabilityLink
      placement={placement}
      position={position}
    />
  </>
);
