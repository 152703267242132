import { Card } from 'braid-design-system';
import { useCallback, type BaseSyntheticEvent } from 'react';
import { useInView } from 'react-intersection-observer';

import { useZoneFeatures } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import {
  selectIsJDP,
  selectIsJDPSplitView,
  selectLmis,
  selectSolMetadata,
} from 'src/store/selectors';

import DefaultLmis from './DefaultLmis/DefaultLmis';

interface LmisProps {
  showDefault?: boolean;
}

export const useIsNullLmis = ({ showDefault = false }: LmisProps) => {
  const { LMIS_ENABLED } = useZoneFeatures();
  const currentLmis = useSelector(selectLmis);

  return !LMIS_ENABLED || (!currentLmis.content && !showDefault);
};

export const useLmis = ({ showDefault }: LmisProps) => {
  const isNull = useIsNullLmis({ showDefault });
  const currentLmis = useSelector(selectLmis);
  const solMetadata = useSelector(selectSolMetadata);

  const experimentFlightId = solMetadata?.tags?.mordor__flights ?? '';

  const { content, lmisResultType, lmisEntity, lmisSnippet } =
    currentLmis || {};

  return {
    isNull,
    content,
    lmisAnalytics: {
      ...(lmisResultType && { lmisResultType }),
      ...(lmisEntity && { lmisEntity }),
      ...(lmisSnippet && { lmisSnippet }),
      experimentFlightId,
    },
  };
};

const Lmis = ({ showDefault = false }: LmisProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const isJDP = useSelector(selectIsJDP);
  const isJDPSplitView = useSelector(selectIsJDPSplitView);
  const { isNull, content, lmisAnalytics } = useLmis({
    showDefault,
  });
  const isJobDetailsView = isJDP || isJDPSplitView;

  const lmisLinkClicked = useCallback(
    (event: BaseSyntheticEvent) => {
      const href = event.target.closest('a')?.href;

      if (href) {
        analyticsFacade.lmisClicked(lmisAnalytics);
      }
    },
    [analyticsFacade, lmisAnalytics],
  );

  const { ref: trackedComponent } = useInView({
    onChange(inView) {
      if (inView) {
        analyticsFacade.lmisImpression(lmisAnalytics);
      }
    },
    delay: 1000,
    threshold: 0.5,
    triggerOnce: true,
  });

  if (isJobDetailsView && isNull) {
    return null;
  }

  if (isJobDetailsView) {
    return (
      <div ref={trackedComponent}>
        {content ? (
          <div
            data-automation="dynamic-lmis"
            dangerouslySetInnerHTML={{ __html: content }}
            onClick={lmisLinkClicked}
            style={{ zIndex: 0, position: 'relative' }}
          />
        ) : (
          showDefault && <DefaultLmis />
        )}
      </div>
    );
  }

  return (
    <div ref={trackedComponent}>
      {content ? (
        <div
          data-automation="dynamic-lmis"
          dangerouslySetInnerHTML={{ __html: content }}
          onClick={lmisLinkClicked}
          style={{ zIndex: 0, position: 'relative' }}
        />
      ) : (
        showDefault && (
          <Card>
            <DefaultLmis />
          </Card>
        )
      )}
    </div>
  );
};

export default Lmis;
