export const IconWorkingRights = () => (
  <svg
    width="105"
    height="104"
    viewBox="0 0 105 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3078_11257)">
      <path
        d="M98.0461 0H70.1371C66.7477 0 64 2.74766 64 6.13706V40.9137C64 44.3031 66.7477 47.0508 70.1371 47.0508H98.0461C101.435 47.0508 104.183 44.3031 104.183 40.9137V6.13706C104.183 2.74766 101.435 0 98.0461 0Z"
        fill="#FCD9EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.4133 15.2319H98.4373V17.1155H86.4133V15.2319Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.4133 9.09521H98.4373V10.9788H86.4133V9.09521Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.2412 31.7886H98.4371V33.6722H71.2412V31.7886Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.7458 25.6521H98.4373V27.5357H69.7458V25.6521Z"
        fill="#F8B1DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.3748 37.9253H96.8082V39.8089H71.3748V37.9253Z"
        fill="#F8B1DC"
      />
      <path
        d="M79.2476 6.32812H73.7238C71.5058 6.32812 69.7078 8.12617 69.7078 10.3442V15.8679C69.7078 18.0859 71.5058 19.884 73.7238 19.884H79.2476C81.4656 19.884 83.2636 18.0859 83.2636 15.8679V10.3442C83.2636 8.12617 81.4656 6.32812 79.2476 6.32812Z"
        fill="#072462"
      />
      <path
        d="M80.3879 17.7947C80.3879 15.6396 78.6409 13.8926 76.4858 13.8926C74.3308 13.8926 72.5837 15.6396 72.5837 17.7947H80.3879Z"
        fill="white"
      />
      <path
        d="M76.4857 12.9177C77.5633 12.9177 78.4368 12.0442 78.4368 10.9667C78.4368 9.88914 77.5633 9.01562 76.4857 9.01562C75.4082 9.01562 74.5347 9.88914 74.5347 10.9667C74.5347 12.0442 75.4082 12.9177 76.4857 12.9177Z"
        fill="white"
      />
      <path
        d="M40.0002 22.0918C17.9087 22.0918 -1e-05 40.4274 0 63.0456C1e-05 85.6638 17.9087 103.999 40.0002 103.999C62.0915 103.999 80 85.6637 80 63.0456C80 55.9619 78.2425 49.2988 75.1512 43.4869L75.6135 43.9604L78.1213 29.2564L65.5182 31.5096C58.5928 25.6288 49.7005 22.0918 40.0002 22.0918Z"
        fill="white"
      />
      <path
        d="M52.5637 40.7046L53.3959 43.5972C53.4683 43.8488 53.6171 44.0686 53.8196 44.2231L55.1945 45.2725C55.4024 45.4311 55.5534 45.6583 55.6236 45.9177L56.9865 50.9595C57.0417 51.1637 57.1473 51.3489 57.2927 51.4965L62.4619 56.7435C62.5848 56.8681 62.6795 57.02 62.7392 57.188L65.0704 63.7438C65.1592 63.9935 65.1665 64.2671 65.0913 64.5216L61.2732 77.433C60.9335 78.582 60.047 79.4616 58.9348 79.7535L56.3713 80.4262C55.3957 80.6823 54.3703 80.6383 53.4178 80.2997L49.9661 79.0727C49.2978 78.835 48.7225 78.3733 48.329 77.7586L45.6975 73.6472C45.3247 73.0647 44.5582 72.9387 44.0334 73.3735L43.5876 73.7429C43.1405 74.1135 42.5019 74.0837 42.0879 73.673L38.2697 69.8856C38.022 69.6399 37.683 69.5225 37.3445 69.5651L30.1461 70.4716C29.21 70.5895 28.2947 70.8473 27.4281 71.2373L21.6955 73.817L20.5327 74.3219C19.6105 74.7224 18.5554 74.583 17.7566 73.9551C17.1751 73.498 16.7836 72.8236 16.6634 72.0718L14.9529 61.374C14.7587 60.1593 14.8607 58.9129 15.2494 57.7507L16.0111 55.4739C16.3107 54.5782 16.9828 53.8743 17.8371 53.5618L24.0669 51.2822C24.2502 51.2152 24.4143 51.1004 24.5438 50.9487L30.2378 44.2794C30.4973 43.9754 30.886 43.83 31.2703 43.8932L33.1253 44.1983C33.4168 44.2462 33.7147 44.1745 33.9571 43.9981L38.7848 40.4847C39.0758 40.2729 39.4438 40.214 39.7819 40.3251L43.0651 41.4041C43.5624 41.5675 43.8924 42.0619 43.8686 42.6081L43.7612 45.0789C43.7396 45.5758 44.0115 46.0356 44.4454 46.236L47.7709 47.7719C48.4553 48.088 49.2405 47.6439 49.365 46.8701L50.3333 40.8521C50.3769 40.5814 50.5072 40.3344 50.7029 40.1522C51.3294 39.5683 52.3216 39.8629 52.5637 40.7046Z"
        fill="#EE399E"
      />
      <path
        d="M56.0395 83.7352L57.8656 83.987C58.4728 84.0707 58.8326 84.7436 58.5841 85.331L57.5936 87.6721C57.402 88.125 56.9139 88.3511 56.4659 88.1944L55.889 87.9927C55.5504 87.8743 55.3081 87.5606 55.2679 87.1886L55.0092 84.7974C54.942 84.1761 55.4487 83.6537 56.0395 83.7352Z"
        fill="#EE399E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4166 54.6095C45.9948 55.1606 46.0168 56.0761 45.4658 56.6543L39.6409 62.7662C39.3679 63.0526 38.9896 63.2147 38.5939 63.2147C38.1983 63.2147 37.8199 63.0526 37.547 62.7662L34.5343 59.6051C33.9833 59.0269 34.0053 58.1114 34.5835 57.5604C35.1617 57.0093 36.0772 57.0313 36.6283 57.6095L38.5939 59.6721L43.3718 54.6587C43.9229 54.0805 44.8384 54.0585 45.4166 54.6095Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3078_11257">
        <rect width="104.183" height="104" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
